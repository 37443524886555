import React, { Component } from "react";
import { connect } from "react-redux";
import SingleRoute from "../routeComponent/singleRoute";
import { routeUpdate, routeDelete } from "./../../actions/routes";
import { showToast } from "../../common/ToastMsg";
import { getRouteList } from "./../../../redux/action/route";

class RouterEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationOptions: [],
      routerName: this.props.allData?.name || "",
      locationArray: [],
      sortLocationList: "",
      loading: false,
      routeArray: [],
    };
  }
  componentDidMount = () => {
    let locationData = [];
    this.props.allData.route.forEach((loc) => {
      locationData.push(loc.location);
    });
    this.setState({
      locationArray: locationData,
      routeArray: this.props.allData.route,
    });
  };

  handleRaceName = (e) => {
    this.setState({
      routerName: e.target.value,
    });
  };

  handleRouteChange = (e) => {
    this.setState({
      location1: e.target.value,
    });
  };

  handleUpdateRoute = async () => {
    const routeUpdatedata = {
      name: this.state.routerName,
      route: this.state.sortLocationList.toString(),
    };
    this.setState({
      loading: true,
    });
    const res = await routeUpdate(this.props.allData._id, routeUpdatedata);
    if (res) {
      this.setState({
        loading: false,
      });
      showToast("success", "Route updated successfully");
      this.props.GetRouteList(this.props?.raceData?.item?._id);
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleDeleteRoute = async () => {
    const res = await routeDelete(this.props.allData._id);
    if (res) {
      showToast("success", "Route deleted successfully");
      this.props.GetRouteList(this.props?.raceData?.item?._id);
    }
  };

  handleChange = (id, index) => {
    const oldArray = this.state.routeArray;
    let oldLocation = oldArray[index]?.location;

    oldArray[index]["location"] = id;
    let updatedNewArray = [];

    oldArray.forEach((route, i) => {
      if (
        route.order !== index + 1 &&
        route?.location?.toString() === id.toString()
      ) {
        oldArray[i]["location"] = oldLocation;
      }
    });

    oldArray.forEach((route) => {
      updatedNewArray.push(route?.location);
    });

    this.props.locations.forEach((data, i) => {
      if (!updatedNewArray.includes(data.id)) {
        updatedNewArray.forEach((location, i) => {
          if (!location) {
            updatedNewArray[i] = data.id;
          }
        });
      }
    });
    this.setState({
      sortLocationList: updatedNewArray,
      routeArray: oldArray,
    });
  };
  render() {
    return (
      <>
        {this.state.loading ? (
          <div className="spinner spinner-5" />
        ) : (
          <form style={{ width: "100%" }}>
            <div className="d-flex mb-3 flex-wrap">
              <div className="label-box mr-3">
                <label htmlFor="location">Route Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleEmail"
                  placeholder="Race name"
                  value={this.state.routerName}
                  onChange={this.handleRaceName}
                />
              </div>
              <div className="select-box d-flex flex-wrap">
                {this.state.routeArray.map((item, indx) => {
                  return (
                    <SingleRoute
                      key={item.id.toString()}
                      index={indx}
                      data={item}
                      locations={this.props.locations}
                      locationArray={this.state.locationArray}
                      handleChange={(id) => this.handleChange(id, indx)}
                      disable={
                        item.orderStatus === "START" ||
                        item.orderStatus === "END"
                          ? true
                          : false
                      }
                    />
                  );
                })}
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <i
                  className="fas fa-save fa-lg mr-2"
                  style={{ color: "#878793" }}
                  onClick={this.handleUpdateRoute}
                ></i>

                <i
                  className="fas fa-trash fa-lg"
                  style={{ color: "#878793" }}
                  onClick={this.handleDeleteRoute}
                ></i>
                {/* <Button
                type="button"
                className=" btn-pill redbutton   btn-primary mr-1"
              >
                Edit
              </Button>
              <Button
                type="button"
                className="btn btn-pill redbutton  btn-primary btn btn-primary"
              >
                Delete
              </Button> */}
              </div>
            </div>
          </form>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    locations: state.LocationList.locationList,
    fetching: state.LocationList.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetRouteList: (id) => dispatch(getRouteList(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterEditForm);
