import {
  GET_GROUP_LIST_REQUEST,
  GET_GROUP_LIST_SUCCESS,
  GET_GROUP_LIST_ERROR,
} from "../../utils/constant";
const initialState = {
  fetching: false,
  groupList: [],
  error: {},
};

export const GroupList = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP_LIST_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_GROUP_LIST_SUCCESS:
      return {
        ...state,
        groupList: action.payload,
        fetching: false,
      };
    case GET_GROUP_LIST_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
