import { showToast } from "../common/ToastMsg";
import { apiInstance } from "./../../httpClient/index";

export const getRouteList = async (id) => {
  try {
    const res = await apiInstance.get(`route/race/${id}`);
    if (res.status === 200) {
      return res.data.data;
    } else {
      return null;
    }
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const createRoute = async (routeBody) => {
  try {
    const res = await apiInstance.post(`route/create`, routeBody);
    if (res.status === 200) {
      return res.data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const routeUpdate = async (id, routeUpdatedata) => {
  try {
    const res = await apiInstance.put(`route/update/${id}`, routeUpdatedata);
    if (res.status === 200) {
      return res.data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const routeDelete = async (id) => {
  try {
    const res = await apiInstance.delete(`route/remove/${id}`);
    if (res.status === 200) {
      return res.data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};
