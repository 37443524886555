import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { createQuestions, updateQuestions } from "./../../actions/questions";
import { showToast } from "../../common/ToastMsg";

export default class AddQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      loading: false,
    };
  }

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.setState({ question: this.props.data.question });
    }
  };

  handleQuestion = (e) => {
    this.setState({
      question: e.target.value,
    });
  };

  createQuestion = async (id) => {
    try {
      this.setState({
        loading: true,
      });

      const routeBody = {
        race:
          this.props.isEdit !== "Edit"
            ? this.props?.data?.item?._id
            : this.props?.data?.race,
        question: this.state.question,
      };
      console.log("routeBody: ", routeBody);

      if (this.props.isEdit !== "Edit") {
        const res = await createQuestions(routeBody);
        console.log("000000000res: ", res);
        if (res) {
          this.setState({
            loading: false,
          });
          showToast("success", "Question created successfully");
          this.props.getData();
          this.props.handleClose();
        } else {
          this.setState({
            loading: false,
          });
        }
      } else {
        const res = await updateQuestions(this.props.data.id, routeBody);
        if (res) {
          this.setState({
            loading: false,
          });
          this.props.getData();
          this.props.handleClose();
          showToast("success", "Question updated successfully");
        } else {
          this.setState({
            loading: false,
          });
        }
      }
    } catch (e) {
      console.log("e: ", e.response);
    }
  };

  render() {
    return (
      <>
        <Modal.Header>
          <h3 className="modal-title has-icon ms-icon-round ">
            {this.props.isEdit ? "Update Question" : "Add Question"}
          </h3>
          <button
            type="button"
            className="close"
            onClick={this.props.handleClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="question">Question</label>
              <input
                type="text"
                className="form-control"
                id="question"
                placeholder="Enter question"
                value={this.state.question}
                onChange={this.handleQuestion}
              />
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-light btn-pill mr-2"
              onClick={this.props.handleClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary redbutton btn-pill"
              onClick={this.createQuestion}
            >
              {this.props?.isEdit !== "Edit" ? "Create" : "update"}
              {this.state.loading && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="ml-2"
                />
              )}
            </button>
          </div>
        </Modal.Body>
      </>
    );
  }
}
