import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { createOptions } from "./../../actions/race";

export default class AddRegistrationField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      addRegistrationField: "",
      loading: false,
      isMember: false,
      isTeam: false,
    };
    this.handleCreateOptions = this.handleCreateOptions.bind(this);
  }

  handleRegistartionField = (e) => {
    this.setState({
      addRegistrationField: e.target.value,
    });
  };

  handleChangeType = (e) => {
    console.log("e: ", e.target.value);
    // this.setState({
    //   isMember: e.target.value,
    // });
    const val = e.target.value;
    if (val === "member") {
      this.setState({
        isMember: true,
        isTeam: false,
      });
    } else {
      this.setState({
        isMember: false,
        isTeam: true,
      });
    }
  };

  handleCreateOptions = async (e) => {
    e.preventDefault();
    const optionData = {
      name: this.state.addRegistrationField,
      isMember: this.state.isMember,
      isTeam: this.state.isTeam,
    };
    this.setState({
      loading: true,
    });
    const res = await createOptions(optionData);
    if (res) {
      this.setState({
        loading: false,
      });
      this.props.getAllResiField();
      this.props.handleClose();
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    return (
      <>
        <form method="post" onSubmit={this.handleCreateOptions}>
          <Modal.Header>
            <h3 className="modal-title has-icon ms-icon-round ">Add Field</h3>
            <button
              type="button"
              className="close"
              onClick={this.props.handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="ms-form-group has-icon">
              <label>Name</label>
              <input
                required
                type="text"
                placeholder="Enter label"
                className="form-control"
                name="news-letter"
                value={this.state.addRegistrationField}
                onChange={this.handleRegistartionField}
              />
            </div>

            <div className="ms-form-group has-icon registration-form">
              <ul className="ms-list d-flex" onChange={this.handleChangeType}>
                <li className="ms-list-item pl-0">
                  <label className="ms-checkbox-wrap">
                    <input
                      type="radio"
                      name="checkbox"
                      value="member"
                      checked={this.state.isMember === true}
                    />
                    <i className="ms-checkbox-check" />
                  </label>
                  <span> Member </span>
                </li>
                <li className="ms-list-item">
                  <label className="ms-checkbox-wrap">
                    <input
                      type="radio"
                      name="checkbox"
                      value="team"
                      checked={this.state.isTeam === true}
                    />
                    <i className="ms-checkbox-check" />
                  </label>
                  <span> Team </span>
                </li>
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-light btn-pill"
              onClick={this.props.handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              id="submit"
              value="Submit"
              className="btn btn-primary shadow-none btn-pill redbutton"
              // onClick={this.handleOptions}
            >
              Submit
              {this.state.loading && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="ml-2"
                />
              )}
            </button>
          </Modal.Footer>
        </form>
      </>
    );
  }
}
