import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

export default class ShareExportFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      raceID: "",
    };
  }
  componentDidMount() {
    this.setState({
      raceID: this.props?.data?.item?._id,
    });
  }
  headers = [
    { label: "Rank", key: "rank" },
    { label: "Name", key: "name" },
    { label: "Captain Name", key: "captain_name" },
    { label: "Team Id", key: "teamId" },
    { label: "Score", key: "score" },
  ];
  render() {
    return (
      <>
        <div className="d-flex justify-content-end">
          {/* <Button className="btn btn-pill redbutton mb-3 mt-0 mr-2">
            Share
          </Button> */}
          <CSVLink
            data={this.props.resultData}
            headers={this.headers}
            filename={"my-file.csv"}
            className="btn btn-primary btn-pill redbutton mb-3 mt-0 mr-2"
          >
            Export Minimum
          </CSVLink>
          <Link
            to={{
              pathname: `https://therushaway.com/onground/#/race-result?raceid=${this.state.raceID}`,
            }}
            target="_blank"
          >
            <Button className="btn btn-pill redbutton mb-3 mt-0 mr-2">
              Share
            </Button>
          </Link>
        </div>
      </>
    );
  }
}
