import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import $ from "jquery";
import { Dropdown, NavLink } from "react-bootstrap";
import Scrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import costiclogo from "../../assets/img/costic/costic-logo-84x41.png";
import { getData, removeUserSession } from "./../../utils/common";

class Topnavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: getData(),
    };
  }
  addsidenavigation = () => {
    $(".ms-body").toggleClass("ms-aside-left-open");
    $("#ms-side-nav").toggleClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
  };
  topbaropen = () => {
    $("#ms-nav-options").toggleClass("ms-slide-down");
  };

  handleLogout = () => {
    this.props.history.replace("/login");
    removeUserSession();
  };

  render() {
    return (
      <nav className="navbar ms-navbar">
        <div
          className="ms-aside-toggler ms-toggler pl-0"
          onClick={this.addsidenavigation}
        >
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
        </div>
        <div className="logo-sn logo-sm ms-d-block-sm">
          <Link className="pl-0 ml-0 text-center navbar-brand mr-0" to="/">
            <img src={costiclogo} alt="logo" />
          </Link>
        </div>
        <ul className="ms-nav-list ms-inline mb-0" id="ms-nav-options">
          <li className="ms-nav-item ms-search-form pb-0 py-0">
            <form className="ms-form" method="post">
              <div className="ms-form-group my-0 mb-0 has-icon fs-14">
                <input
                  type="search"
                  className="ms-form-input"
                  name="search"
                  placeholder="Search here..."
                />
                <i className="flaticon-search text-disabled" />
              </div>
            </form>
          </li>
          <li className="ms-nav-item ms-nav-user dropdown">
            <Dropdown className="custom-dropdown">
              <Dropdown.Toggle as={NavLink} id="userDropdown" className="p-0">
                <img
                  className="ms-user-img ms-img-round"
                  src="assets/img/costic/customer-6.jpg"
                  alt="people"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu dropdown-menu-right user-dropdown"
                aria-labelledby="userDropdown"
              >
                <div className="dropdown-menu-header">
                  <h6 className="dropdown-header ms-inline m-0">
                    <span className="text-disabled">
                      Welcome, {this.state.data?.username || "Admin"}
                    </span>
                  </h6>
                </div>
                <div className="dropdown-divider" />

                <div className="dropdown-divider" />

                <div className="dropdown-menu-footer">
                  <Link
                    to="#"
                    className="media fs-14 p-2"
                    onClick={this.handleLogout}
                  >
                    <span>
                      <i className="flaticon-shut-down mr-2" /> Logout
                    </span>
                  </Link>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <div
          className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler"
          onClick={this.topbaropen}
        >
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
        </div>
      </nav>
    );
  }
}

export default withRouter(Topnavigation);
