import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { updateGroupList } from "../../actions/group";
import { showToast } from "../../common/ToastMsg";
import { CreateGroupList } from "./../../actions/group";

export default class AddGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: this.props?.data?.name || "",
      loading: false,
    };
  }

  handleGroupName = (e) => {
    this.setState({
      groupName: e.target.value,
    });
  };

  handleCreateGroup = async () => {
    const groupBody = {
      name: this.state.groupName,
    };
    this.setState({
      loading: true,
    });

    if (this.props.data) {
      const res = await updateGroupList(this.props.data._id, groupBody);
      if (res) {
        this.setState({
          loading: false,
        });
        showToast("success", "Group Updated successfully");
        this.props.handleClose();
        this.props.allGroup();
      } else {
        this.setState({
          loading: false,
        });
      }
    } else {
      const res = await CreateGroupList(groupBody);
      if (res) {
        this.setState({
          loading: false,
        });
        showToast("success", "Group added successfully");
        this.props.getAllGroup();
        this.props.handleClose();
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  };

  render() {
    return (
      <>
        <Modal.Header>
          <h3 className="modal-title has-icon ms-icon-round ">
            {this.props?.data ? "Edit Group" : "Add Group"}
          </h3>
          <button
            type="button"
            className="close"
            onClick={this.props.handleClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <label htmlFor="groupname">Group Name</label>
            <input
              type="text"
              className="form-control"
              id="groupname"
              placeholder="Enter name"
              value={this.state.groupName}
              onChange={this.handleGroupName}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light btn-pill mr-2"
            onClick={this.props.handleClose}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary btn-pill redbutton"
            onClick={this.handleCreateGroup}
          >
            {this.props.data ? "Update" : "Add"}
            {this.state.loading && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="ml-2"
              />
            )}
          </button>
        </Modal.Footer>
      </>
    );
  }
}
