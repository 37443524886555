import { apiInstance } from "../../httpClient";
import { showToast } from "../common/ToastMsg";

export const getSkinList = async () => {
  try {
    const res = await apiInstance.get(`skin/get-all`);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const updateSkinList = async (id, skinBody) => {
  try {
    const res = await apiInstance.put(`skin/update/${id}`, skinBody);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const CreateSkin = async (skinBody) => {
  try {
    const res = await apiInstance.post(`skin/create`, skinBody);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const deleteSkin = async (id) => {
  try {
    const res = await apiInstance.delete(`skin/remove/${id}`);
    if (res.status === 200) {
      return res;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};
