import React, { Component } from "react";

import { Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { showToast } from "../../common/ToastMsg";
import { createTeamList, updateTeamList } from "./../../actions/team";
import { getTeamList } from "./../../../redux/action/team";
import { getProgressReport } from "../../../redux/action/report";
import { getData } from "./../../../utils/common";

class GenerateTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      teamName: this.props?.singleTeamData?.name || "",
      idType: this.props?.singleTeamData?.test || false,
      email: this.props?.singleTeamData?.email || "",
      password: this.props?.singleTeamData?.password || "",
      isEdit: false,
      captainName: this.props?.singleTeamData?.captain_name || "",
      routeName: this.props?.singleTeamData?.route?.id || "",
      userId: "",
    };
  }

  componentDidMount() {
    this.setState({
      userId: getData().id,
    });
  }
  handleTeamName = (e) => {
    this.setState({
      teamName: e.target.value,
    });
  };
  handleCaptainName = (e) => {
    this.setState({
      captainName: e.target.value,
    });
  };
  handleRoute = (e) => {
    this.setState({
      routeName: e.target.value,
    });
  };

  handleidType = () => {
    this.setState({
      idType: !this.state.idType,
    });
  };

  handleEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  handlePassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  handleCreateTeam = async () => {
    // e.preventDefault();
    const teamBody = {
      user: this.state.userId,
      race: this.props.data?.item?._id,
      name: this.state.teamName.toLowerCase(),
      test: this.state.idType,
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      captain_name: this.state.captainName,
      route: this.state.routeName,
    };
    console.log("teamBody: ", teamBody);
    this.setState({
      loading: true,
    });

    if (this.props?.isEdit === "Add") {
      const res = await createTeamList(teamBody);
      if (res) {
        this.setState({
          loading: false,
        });
        showToast("success", "Team Created successfully");
        this.props.handleClose();
        this.props.GetTeamList(this.props.data?.item?._id);
        this.props.GetProgressReport(this.props?.data?.item?._id);
      } else {
        this.setState({
          loading: false,
        });
      }
    } else {
      const res = await updateTeamList(this.props.singleTeamData._id, teamBody);
      if (res) {
        this.setState({
          loading: false,
        });
        showToast("success", "Team Updated successfully");
        this.props.handleClose();
        this.props.GetTeamList(this.props.data?.item?._id);
        this.props.GetProgressReport(this.props?.data?.item?._id);
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  };
  render() {
    return (
      <>
        <Modal.Header>
          <h3 className="modal-title has-icon ms-icon-round ">
            {this.props.isEdit ? "Add Teams" : "Edit Teams"}
          </h3>
          <button
            type="button"
            className="close"
            onClick={this.props.handleClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="teamName">Team name</label>
              <input
                type="text"
                className="form-control"
                id="teamName"
                placeholder="Team name"
                value={this.state.teamName}
                onChange={this.handleTeamName}
              />
            </div>
            {this.props?.isEdit === "Add" ? (
              <div className="form-group">
                <label className="ms-checkbox-wrap ms-checkbox-primary">
                  <input
                    type="checkbox"
                    name="idtype"
                    checked={this.state.idType}
                    onChange={this.handleidType}
                  />
                  <i className="ms-checkbox-check" />
                </label>
                <span>Test User</span>
              </div>
            ) : null}

            {this.props?.isEdit !== "Add" ? (
              <>
                <div className="form-group">
                  <label htmlFor="captainname">Captain name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="captainname"
                    placeholder="captain name"
                    value={this.state.captainName}
                    onChange={this.handleCaptainName}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="teamName">Route</label>
                  <select
                    className="form-control"
                    id="group"
                    value={this.state.routeName}
                    onChange={this.handleRoute}
                  >
                    {this.props.routes?.map((ele, i) => {
                      return (
                        <>
                          <option value={ele.id} key={i.toString()}>
                            {this.props?.singleTeamData?.route?.id === ele.id
                              ? ele.name
                              : ele.name}
                          </option>
                          {/* {this.props?.singleTeamData?.route?.id === ele.id ? (
                            <option value={ele.id} key={i.toString()}>
                              {this.props?.singleTeamData?.route?.id === ele.id
                                ? ele.name
                                : ele.name}
                            </option>
                          ) : undefined} */}
                        </>
                      );
                    })}
                  </select>
                </div>
              </>
            ) : null}

            <div className="form-group">
              <label htmlFor="email">Email Id</label>
              <input
                type="text"
                disabled={this.props?.isEdit !== "Add"}
                className="form-control"
                id="email"
                placeholder="Enter Email"
                value={this.state.email}
                onChange={this.handleEmail}
              />
            </div>

            {this.props?.isEdit !== "Add" ? (
              <>
                <div className="form-group">
                  <label htmlFor="email">Password</label>
                  <input
                    className="form-control"
                    id="password"
                    placeholder="Update Password"
                    value={this.state.password}
                    onChange={this.handlePassword}
                  />
                </div>
              </>
            ) : null}
          </form>
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-light btn-pill mr-2"
              onClick={this.props.handleClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary redbutton btn-pill"
              onClick={this.handleCreateTeam}
            >
              {this.props?.isEdit === "Add" ? "Create" : "update"}
              {this.state.loading && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="ml-2"
                />
              )}
            </button>
          </div>
        </Modal.Body>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.RouteList.routeList,
    teamFetching: state.TeamList.fetching,
    teams: state.TeamList.teamList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetTeamList: (id) => dispatch(getTeamList(id)),
    GetProgressReport: (id) => dispatch(getProgressReport(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateTeams);
