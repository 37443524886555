import { createAction } from "redux-actions";
import * as constants from "../../utils/constant";

export const getRaceListRequest = createAction(constants.GET_RACE_LIST_REQUEST);
export const getRaceListSuccess = createAction(constants.GET_RACE_LIST_SUCCESS);
export const getRaceListError = createAction(constants.GET_RACE_LIST_ERROR);

export const getLocationListRequest = createAction(
  constants.GET_LOCATION_LIST_REQUEST
);
export const getLocationListSuccess = createAction(
  constants.GET_LOCATION_LIST_SUCCESS
);
export const getLocationListError = createAction(
  constants.GET_LOCATION_LIST_ERROR
);

export const getRouteListRequest = createAction(
  constants.GET_ROUTE_LIST_REQUEST
);
export const getRouteListSuccess = createAction(
  constants.GET_ROUTE_LIST_SUCCESS
);
export const getRouteListError = createAction(constants.GET_ROUTE_LIST_ERROR);

export const getGroupListRequest = createAction(
  constants.GET_GROUP_LIST_REQUEST
);
export const getGroupListSuccess = createAction(
  constants.GET_GROUP_LIST_SUCCESS
);
export const getGroupListError = createAction(constants.GET_GROUP_LIST_ERROR);

export const getRaceByGroupListRequest = createAction(
  constants.GET_RACEBYGROUP_LIST_REQUEST
);
export const getRaceByGroupListSuccess = createAction(
  constants.GET_RACEBYGROUP_LIST_SUCCESS
);
export const getRaceByGroupListError = createAction(
  constants.GET_RACEBYGROUP_LIST_ERROR
);

export const getSkinListRequest = createAction(constants.GET_SKIN_LIST_REQUEST);
export const getSkinListSuccess = createAction(constants.GET_SKIN_LIST_SUCCESS);
export const getSkinListError = createAction(constants.GET_SKIN_LIST_ERROR);

export const getRaceBySkinListRequest = createAction(
  constants.GET_RACEBYSKIN_LIST_REQUEST
);
export const getRaceBySkinListSuccess = createAction(
  constants.GET_RACEBYSKIN_LIST_SUCCESS
);
export const getRaceBySkinListError = createAction(
  constants.GET_RACEBYSKIN_LIST_ERROR
);

export const setRouteLocations = createAction(constants.SET_ROUTE_LOCATIONS);

export const getTeamListRequest = createAction(constants.GET_TEAM_LIST_REQUEST);
export const getTeamListSuccess = createAction(constants.GET_TEAM_LIST_SUCCESS);
export const getTeamListError = createAction(constants.GET_TEAM_LIST_ERROR);

export const getQuestionListRequest = createAction(
  constants.GET_QUESTION_LIST_REQUEST
);
export const getQuestionListSuccess = createAction(
  constants.GET_QUESTION_LIST_SUCCESS
);
export const getQuestionListError = createAction(
  constants.GET_QUESTION_LIST_ERROR
);

export const getProgressReportRequest = createAction(
  constants.GET_PROGRESS_REPORT_REQUEST
);
export const getProgressReportSuccess = createAction(
  constants.GET_PROGRESS_REPORT_SUCCESS
);
export const getProgressReportError = createAction(
  constants.GET_PROGRESS_REPORT_ERROR
);

export const getReviewListRequest = createAction(
  constants.GET_REVIEW_LIST_REQUEST
);
export const getReviewListSuccess = createAction(
  constants.GET_REVIEW_LIST_SUCCESS
);
export const getReviewListError = createAction(constants.GET_REVIEW_LIST_ERROR);

export const getTeamStatusRequest = createAction(
  constants.GET_TEAM_STATUS_REQUEST
);
export const getTeamStatusSuccess = createAction(
  constants.GET_TEAM_STATUS_SUCCESS
);
export const getTeamStatusError = createAction(constants.GET_TEAM_STATUS_ERROR);
