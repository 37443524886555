import { apiInstance } from "../../httpClient";
import * as actions from "./index";

export const getReviewList = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.getReviewListRequest());
    apiInstance
      .get(`feedback/get-all?raceId=${id}`)
      .then((res) => {
        dispatch(actions.getReviewListSuccess(res.data.data));
        resolve();
      })
      .catch((e) => {
        dispatch(actions.getReviewListError(e?.response?.data?.message));
        reject();
      });
  });
