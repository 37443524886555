import React, { Component } from "react";
import { getReviewList } from "./../../../redux/action/reviews";
import { connect } from "react-redux";

class TeamReview extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this.props.GetReviewList(this.props.data?.item?.id);
  };

  render() {
    return (
      <>
        <div className="ms-panel-header">
          <h6 className="panel-title">Reviews</h6>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Team Name</th>
              <th scope="col">Review</th>
              <th scope="col">Rating</th>
            </tr>
          </thead>
          <tbody>
            {this.props.reviews && this.props.reviews.length > 0 ? (
              this.props.reviews.map((data, i) => {
                return (
                  <tr key={i.toString()}>
                    <th scope="row">{data?.teamId?.name} </th>
                    <td>{data?.text}</td>
                    <td>
                      {data?.rating === 1
                        ? `🙁`
                        : data?.rating === 2
                        ? `😐`
                        : data?.rating === 3
                        ? `😃`
                        : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Result Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    reviews: state.ReviewList.reviewList,
    fetching: state.ReviewList.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetReviewList: (id) => dispatch(getReviewList(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamReview);
