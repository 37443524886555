import React, { Component } from "react";
import { Link } from "react-router-dom";

class Breadcrumb extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-4">
          {/* <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0 mb-0">
              <li className="breadcrumb-item">
                <Link to="#">
                  <i className="material-icons">home</i> Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="#">Customer</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Customer List
              </li>
            </ol>
          </nav> */}
          <h4 className="heading-title">
            {this.props?.data?.item?.name || "Rush -A-Way On Ground App"}
          </h4>
          <Link to="/add-race">
            <button
              type="button"
              className="btn btn-pill btn-primary m-0 redbutton"
            >
              + Add A Race
            </button>
          </Link>
        </div>
      </>
    );
  }
}

export default Breadcrumb;
