import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Preloader from "./components/layouts/Preloader";
import Dashboard from "./components/pages/Dashboard";
import Defaultlogin from "./components/pages/Defaultlogin";
import Error from "./components/pages/Error";
import Racelist from "./components/pages/Racelist";
import "./assets/css/custom.scss";
import AddRace from "./components/pages/AddRace";
import ViewEditRace from "./components/pages/ViewEditRace";
import { Toast } from "./components/common/ToastMsg";
import { apiInstance } from "./httpClient/index";
import { getToken } from "./utils/common";
import { Provider } from "react-redux";
import configureStore from "./redux/store/store";
import Racegroup from "./components/pages/Racegroup";
import Skin from "./components/pages/Skin";
import AddSkin from "./components/pages/AddSkin";
import RaceByGroup from "./components/pages/Groupbyrace";

// import RaceByGroup from "./components/sections/Group-by-race";
import Skinbyrace from "./components/pages/Skinbyrace";

const store = configureStore();

function App() {
  Object.assign(apiInstance.defaults.headers, {
    Authorization: getToken(),
  });
  return (
    <Provider store={store}>
      <Router basename="/">
        <Preloader />
        <Toast />
        <Switch>
          <Route exact path="/login" component={Defaultlogin} />
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/error" component={Error} />
          <Route exact path="/race-list" component={Racelist} />
          <Route exact path="/add-race" component={AddRace} />
          <Route exact path="/view-edit-race" component={ViewEditRace} />
          <Route exact path="/group-list" component={Racegroup} />
          <Route exact path="/skin-list" component={Skin} />
          <Route exact path="/add-skin" component={AddSkin} />
          <Route exact path="/group-race-list" component={RaceByGroup} />
          <Route exact path="/race-skin-list" component={Skinbyrace} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
