/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import AddQuestion from "./AddQuestion";
import { questionDelete } from "./../../actions/questions";
import { showToast } from "../../common/ToastMsg";

class RouteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelOpen: false,
      propsData: {},
    };
  }

  handleQuestionDeleteModal = async (item) => {
    const res = await questionDelete(item.id);
    if (res) {
      this.props.getData();
      showToast("success", "Question have been deleted");
    } else {
      showToast("error", "Somthing went wrong. try again later!");
    }
  };

  handleQuestionModal = (item) => {
    this.setState({ modelOpen: !this.state.modelOpen, propsData: item });
  };

  render() {
    return (
      <>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Question</th>

                <th scope="col" style={{ textAlign: "right" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.fetching ? (
                <div className="spinner spinner-5" />
              ) : this.props?.questionList &&
                this.props?.questionList.length > 0 ? (
                this.props?.questionList.map((item, inx) => {
                  return (
                    <tr>
                      <th scope="row">{inx + 1}</th>
                      <td>{item.question}</td>

                      <td style={{ textAlign: "right" }}>
                        <i
                          onClick={() => this.handleQuestionModal(item)}
                          className="fas fa-edit fa-md"
                          style={{ color: "#666674" }}
                        ></i>
                        <i
                          onClick={() => this.handleQuestionDeleteModal(item)}
                          className="fas fa-trash fa-md"
                          style={{ color: "#666674" }}
                        ></i>
                      </td>
                    </tr>
                  );
                })
              ) : (
                "No Data Found"
              )}
            </tbody>
          </table>
        </div>
        <Modal
          show={this.state.modelOpen}
          size="md"
          onHide={this.handleQuestionModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <AddQuestion
            handleClose={this.handleQuestionModal}
            isEdit="Edit"
            data={this.state.propsData}
            getData={() => this.props.getData()}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.RouteList.routeList,
    fetching: state.RouteList.fetching,
  };
};

export default connect(mapStateToProps, null)(RouteList);
