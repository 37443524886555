import {
  GET_TEAM_STATUS_REQUEST,
  GET_TEAM_STATUS_SUCCESS,
  GET_TEAM_STATUS_ERROR,
} from "../../utils/constant";
const initialState = {
  fetching: false,
  teamStatusData: [],
  error: {},
};

export const TeamStatus = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAM_STATUS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_TEAM_STATUS_SUCCESS:
      return {
        ...state,
        teamStatusData: action.payload,
        fetching: false,
      };
    case GET_TEAM_STATUS_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
