import React, { Component } from "react";

class SearchBar extends Component {
  render() {
    return (
      <form className="ms-form mb-3">
        <div className="ms-form-group my-0 mb-0 has-icon fs-14">
          <input
            type="search"
            className="ms-form-input custom-search-box"
            name="search"
            placeholder="Search here..."
            onChange={this.props.handleSearch}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
            // className="foodsearch"
            //   value={this.seachValue}
          />
          <i className="flaticon-search text-disabled" />
        </div>
      </form>
    );
  }
}

export default SearchBar;
