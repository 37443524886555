import React, { Component } from "react";
import moment from "moment";
import { Modal, Spinner } from "react-bootstrap";
import { apiInstance } from "./../../../httpClient/index";
import { getTeamStatus } from "../../../redux/action/team";
import { connect } from "react-redux";

class TeamStatusDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      teamStatusData: [],
      showModal: false,
      checkOut: "",
      locationId: "",
      timeLoading: false,
    };
  }
  componentDidMount() {
    if (this.props?.data?.id) {
      this.props.GetTeamStatus(this.props?.data?.id);
      // this.getTeamStatus(this.props?.data?.id);
      // console.log("teannn ", this.props?.data?.id);
    }
  }

  handleModal = (id) => {
    console.log("id: ", id);
    this.setState({
      showModal: true,
      locationId: id,
    });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  handleTime = (event) => {
    this.setState({
      checkOut: event.target.value,
    });
  };
  handleUpdateTime = async () => {
    this.setState({
      timeLoading: true,
    });
    const timedata = {
      team: this.props?.data?.id,
      location: this.state.locationId,
      second: Number(this.state.checkOut),
    };
    try {
      const res = await apiInstance.post(`team/add-second`, timedata);
      console.log("res: ", res);
      this.setState({
        timeLoading: false,
      });
      this.props.GetTeamStatus(this.props?.data?.id);

      this.handleClose();
    } catch (e) {
      console.log("e: ", e.response);
    }
    console.log("timedata: ", timedata);
  };
  render() {
    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive">
              <table className="table table-striped ">
                <thead>
                  <tr>
                    <th scope="col">Location</th>
                    <th scope="col">Check-in</th>
                    <th scope="col">Check-out</th>
                    <th scope="col">Time Saved</th>
                    <th scope="col">Penalty</th>
                    <th scope="col">Points</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {this.props.fetching ? (
                  <div className="spinner spinner-5" />
                ) : this.props.teamStatus &&
                  this.props.teamStatus.length > 0 ? (
                  <tbody>
                    {this.props.teamStatus.map((item, indx) => {
                      console.log("item: ", item);
                      return (
                        <tr key={indx.toString()}>
                          <td scope="col">{item?.location}</td>
                          <td>
                            {moment(parseInt(item?.checkIn)).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </td>

                          <td scope="col">
                            {item?.checkOut
                              ? moment(parseInt(item?.checkOut)).format(
                                  "DD/MM/YYYY HH:mm:ss"
                                )
                              : "-"}
                          </td>
                          <td scope="col">{item?.timeSave}</td>
                          <td scope="col">{item?.penalty}</td>
                          <td scope="col">{item?.points}</td>
                          <td scope="col">
                            <i
                              className="fas fa-edit fa-md"
                              style={{ color: "#878793" }}
                              onClick={() => this.handleModal(item.locationId)}
                            ></i>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  "No Data Found"
                )}
              </table>
              <Modal
                show={this.state.showModal}
                size="sm"
                onHide={this.handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="checkout">Update Checkout time</label>
                    <input
                      type="number"
                      className="form-control"
                      id="checkout"
                      placeholder="Enter Time"
                      value={this.state.checkOut}
                      onChange={this.handleTime}
                    />
                  </div>
                  <div class="d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-light btn-pill mr-2"
                      onClick={this.handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary redbutton btn-pill"
                      onClick={this.handleUpdateTime}
                    >
                      Update
                      {this.state.timeLoading && (
                        <Spinner
                          animation="border"
                          variant="light"
                          size="sm"
                          className="ml-2"
                        />
                      )}
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teamStatus: state.TeamStatus.teamStatusData,
    fetching: state.TeamStatus.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetTeamStatus: (id) => dispatch(getTeamStatus(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamStatusDetail);
