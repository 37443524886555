import React, { Component } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import Switch from "react-switch";
import AddRegistrationField from "./AddRegistrationField";
import {
  getRegiFieldList,
  getSingleRace,
  updateRace,
} from "../../actions/race";
import CheckboxField from "./CheckboxField";
import { createRace } from "./../../actions/race";
import { showToast } from "../../common/ToastMsg";
import { withRouter } from "react-router-dom";
import { IMAGE_URL } from "./../../../utils/common";
import JoditEditor from "jodit-react";
import DatePicker from "react-datepicker";
import raceLogo from "../../../assets/logo.png";
import "react-dropzone-uploader/dist/styles.css";
import { getGroupList } from "../../actions/group";
import { getSkinList } from "./../../actions/skin";
import "react-datepicker/dist/react-datepicker.css";

export class AddRaceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      raceData: "",
      startDate: new Date(),
      endDate: new Date(),
      name: "",
      group: "",
      skin: "",
      voucher: "",
      minTeam: 1,
      maxTeam: "",
      pointSecond: "",
      pointQuestion: "",
      penaltyPerQuestion: "",
      finishMessage: "",
      welcomeMessage: "",
      penaltyPerLocation: "",
      emailFrom: "",
      emailSubject: "",
      emailMessage: "",
      registrationMsg: "",
      raceBrefingDesc: "",
      emergencyContact: "",
      teamKey: "",
      isAutoFinishRaceChecked: false,
      toggleChecked: false,
      options: [],
      file: [],
      uploadFile: [],
      fileEmail: [],
      uploadEmailFile: [],
      regFormData: [],
      loading: false,
      cloneLoading: false,
      content: "",
      racefiles: [],
      groupList: [],
      skinList: [],
      mediaFile: [],
      uploadMediaFile: [],
      uploadType: "",
      raceLogoImg: "",
      raceMediaImg: "",
      emailLogoImg: "",
      isEmailNull: false,
      isRaceNull: false,
      isBreifingNull: false,
      mediaType: "",
      // getCurrentDate: "",
      countForRaceName: 0,
      videoURL: "",
      isCreate: false,
    };

    // this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.uploadSingleFile = this.uploadSingleFile.bind(this);
    this.uploadEmailSingleFile = this.uploadEmailSingleFile.bind(this);
    this.handleToggleChange = this.handleToggleChange.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getAllResiField = this.getAllResiField.bind(this);
  }

  componentDidMount() {
    this.getAllResiField();
    this.getAllGroup();
    this.getAllSkin();
    if (this.props?.data?.item?._id) {
      this.getOneRace(this.props.data.item._id);
    }
  }

  getOneRace = async (id) => {
    try {
      const res = await getSingleRace(id);
      console.log("res: ", res);
      if (res) {
        this.setState({
          raceData: res,
          startDate: moment(res.startDay).toDate(),
          endDate: moment(res.endDay).toDate(),
          name: res.name,
          group: res.group || "",
          skin: res.settings.skin,
          voucher: res.settings.voucher || "",
          minTeam: res.settings.teamSize?.min || "",
          maxTeam: res.settings.teamSize?.max || "",
          pointSecond: res.settings?.pointsPerSeconds || "",
          pointQuestion: res.settings?.pointsPerQustion || "",
          penaltyPerQuestion: res.settings?.penaltyPerQustion || "",
          finishMessage: res.messages?.finishMessage || "",
          welcomeMessage: res.messages?.welcomeMessage || "",
          penaltyPerLocation: res.settings?.penaltyPerLocation || "",
          emailFrom: res.messages?.emailRegistrationForm || "",
          emailSubject: res.messages?.emailRegistrationSubject || "",
          emailMessage: res.messages?.emailRegistrationMessage || "",
          registrationMsg: res.messages?.registrationMessage || "",
          raceBrefingDesc: res.messages?.breifingText || "",
          isAutoFinishRaceChecked: res.settings?.autoFinishRace || false,
          toggleChecked: res.settings?.leaderboard,
          options: res.registrationForm.map((ele) => ele._id) || [],
          raceLogoImg: res.settings?.raceLogo,
          raceMediaImg: res.messages?.breifing?.media,
          emailLogoImg: res.settings?.emailLogo,
          mediaType: res.messages?.breifing?.mediaType,
          videoURL:
            res.messages?.breifing?.mediaType === "video"
              ? res.messages?.breifing.media
              : "",
          emergencyContact: res.emergencyContact || "",
          teamKey: res.teamKey || "",
        });
      }
    } catch (e) {
      console.log("e: ", e.response);
    }
  };

  getAllGroup = async () => {
    const res = await getGroupList();
    if (res) {
      this.setState({
        groupList: res,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  getAllSkin = async () => {
    const res = await getSkinList();
    if (res) {
      this.setState({
        skinList: res,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleWelcomeMessage = (newContent) => {
    this.setState({
      welcomeMessage: newContent,
    });
  };

  handleFinishMessage = (newContent) => {
    this.setState({
      finishMessage: newContent,
    });
  };

  getAllResiField = async () => {
    const res = await getRegiFieldList();
    if (res) {
      this.setState({
        regFormData: res,
      });
    }
  };
  uploadSingleFile(e) {
    this.setState({
      file: URL.createObjectURL(e.target.files[0]),
      uploadFile: e.target.files[0],
      isRaceNull: false,
    });
  }

  handleRemoveImg = () => {
    this.setState({
      file: [],
      uploadFile: [],
      isRaceNull: true,
    });
  };
  handleVideoUrl = (e) => {
    this.setState({
      videoURL: e.target.value,
    });
  };
  uploadMediaFile = (e) => {
    this.setState({
      mediaFile: URL.createObjectURL(e.target.files[0]),
      uploadMediaFile: e.target.files[0],
      isBreifingNull: false,
    });

    setTimeout(() => {
      const uplodMedia = this.state.uploadMediaFile.type.split("/");
      this.setState({
        uploadType: uplodMedia[0],
      });
    }, 500);
  };

  handleRemoveMedia = () => {
    this.setState({
      mediaFile: [],
      uploadMediaFile: [],
      isBreifingNull: true,
    });
  };

  handleRemovePropsImg = () => {
    this.setState({
      raceLogoImg: "",
      isRaceNull: true,
    });
  };
  handleRemoveEmailPropsImg = () => {
    this.setState({
      emailLogoImg: "",
      isEmailNull: true,
    });
  };
  handleRemoveMediaProps = () => {
    this.setState({
      raceMediaImg: "",
      isBreifingNull: true,
    });
  };

  uploadEmailSingleFile(e) {
    this.setState({
      fileEmail: URL.createObjectURL(e.target.files[0]),
      uploadEmailFile: e.target.files[0],
      isEmailNull: false,
    });
  }

  handleRemoveEmailImg = () => {
    this.setState({
      fileEmail: [],
      isEmailNull: true,
    });
  };

  handleModal() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleRaceName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleStartDate = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleEndDate(date) {
    this.setState({
      endDate: date,
    });
  }

  handleGroup = (event) => {
    this.setState({
      group: event.target.value,
    });
  };

  handleTeamKey = (event) => {
    this.setState({
      teamKey: event.target.value,
    });
  };

  handleSkin = (event) => {
    this.setState({
      skin: event.target.value,
    });
  };

  handleTeamMin = (event) => {
    this.setState({
      minTeam: event.target.value,
    });
  };

  handleTeamMax = (event) => {
    this.setState({
      maxTeam: event.target.value,
    });
  };

  handleToggleChange = (toggleChecked) => {
    this.setState({ toggleChecked });
  };

  handlePointsSecond = (event) => {
    this.setState({
      pointSecond: event.target.value,
    });
  };

  handleEmergency = (event) => {
    this.setState({
      emergencyContact: event.target.value,
    });
  };

  handlePenaltyPerLocation = (event) => {
    this.setState({
      penaltyPerLocation: event.target.value,
    });
  };

  handlePointsQuestion = (event) => {
    this.setState({
      pointQuestion: event.target.value,
    });
  };

  handlePenaltyPerQuestion = (event) => {
    this.setState({
      penaltyPerQuestion: event.target.value,
    });
  };

  handleVoucher = (event) => {
    this.setState({
      voucher: event.target.value,
    });
  };

  handleAutoChange = () => {
    this.setState({
      isAutoFinishRaceChecked: !this.state.isAutoFinishRaceChecked,
    });
  };

  handleEmailFrom = (event) => {
    this.setState({
      emailFrom: event.target.value,
    });
  };

  handleEmailSubject = (event) => {
    this.setState({
      emailSubject: event.target.value,
    });
  };

  handleEmailMessage = (newContent) => {
    this.setState({
      emailMessage: newContent,
    });
  };

  handleRegistrationMsg = (newContent) => {
    this.setState({
      registrationMsg: newContent,
    });
  };

  handleBrefingDesc = (newContent) => {
    this.setState({
      raceBrefingDesc: newContent,
    });
  };
  // handleCodeLocation = (newContent) => {
  //   this.setState({
  //     codeLocation: newContent,
  //   });
  // };
  // handleCodeLocationArabic = (newContent) => {
  //   this.setState({
  //     codeLocationArabic: newContent,
  //   });
  // };

  onChangeValue = (event) => {
    this.setState({
      mediaType: event.target.value ? event.target.value : "",

      // raceMediaImg: "",
      uploadMediaFile: [],
      mediaFile: [],
      videoURL: "",
    });
  };
  handleOption = (data) => {
    let arr = this.state.options;
    if (arr.includes(data.id)) {
      const indx = arr.indexOf(data.id);
      arr.splice(indx, 1);
      this.setState({
        options: arr,
      });
    } else {
      arr.push(data.id);
      this.setState({
        options: arr,
      });
    }
  };

  fieldValication = () => {
    let isValid = true;
    const errors = {};
    if (this.state.name === "") {
      errors.username = "Race name Required!!!";
      isValid = false;
    }
    if (this.state.welcomeMessage === "") {
      errors.welcome = "welcome message Required!!!";
      isValid = false;
    }
    if (this.state.finishMessage === "") {
      errors.finish = "Finish message Required!!!";
      isValid = false;
    }
    if (this.state.raceBrefingDesc === "") {
      errors.breifing = "Race breifing Required!!!";
      isValid = false;
    }
    // if (this.state.codeLocation === "") {
    //   errors.checkincode = "Check-in-code Required!!!";
    //   isValid = false;
    // }
    if (this.state.skin === "") {
      errors.skinname = "skin field Required!!!";
      isValid = false;
    }
    this.setState({
      errors,
    });
    return isValid;
  };

  handleCreateRace = async () => {
    if (this.fieldValication()) {
      const formData = new FormData();

      formData.append(
        "name",
        this.state.countForRaceName === 0
          ? this.state.name
          : this.state.name + "" + this.state.countForRaceName
      );
      formData.append("startDay", this.state.startDate);
      formData.append("endDay", this.state.endDate);
      formData.append("group", this.state.group);
      formData.append("skin", this.state.skin);
      formData.append("min", this.state.minTeam);
      formData.append("max", this.state.maxTeam);
      formData.append("autoFinishRace", this.state.isAutoFinishRaceChecked);
      formData.append("leaderboard", this.state.toggleChecked);
      formData.append("pointsPerSeconds", this.state.pointSecond);
      formData.append("penaltyPerLocation", this.state.penaltyPerLocation);
      formData.append("pointsPerQustion", this.state.pointQuestion);
      formData.append("penaltyPerQustion", this.state.penaltyPerQuestion);
      formData.append("voucher", this.state.voucher);
      formData.append("registrationForm", this.state.options);
      formData.append("welcomeMessage", this.state.welcomeMessage);
      formData.append("finishMessage", this.state.finishMessage);
      formData.append("emailRegistrationForm", this.state.emailFrom);
      formData.append("emailRegistrationSubject", this.state.emailSubject);
      formData.append("emailRegistrationMessage", this.state.emailMessage);
      formData.append("registrationMessage", this.state.registrationMsg);
      formData.append("breifingText", this.state.raceBrefingDesc);

      formData.append("raceLogo", this.state.uploadFile);
      formData.append("emailLogo", this.state.uploadEmailFile);
      formData.append("breifing", this.state.uploadMediaFile);
      formData.append("isEmailNull", this.state.isEmailNull);
      formData.append("isRaceNull", this.state.isRaceNull);
      formData.append("isBreifingNull", this.state.isBreifingNull);
      formData.append("teamKey", this.state.teamKey.toLowerCase());

      if (this.state.videoURL && this.state.videoURL.trim() !== "") {
        formData.append("breifingUrl", this.state.videoURL);
      }

      formData.append("emergencyContact", this.state.emergencyContact);

      this.setState({
        loading: true,
      });
      var CurrentDate = moment().format();
      if (this.props?.data?.item) {
        const res = await updateRace(this.props?.data?.item?._id, formData);

        if (res) {
          this.setState({
            loading: false,
            countForRaceName: this.state.countForRaceName + 1,
            getCurrentDate: CurrentDate,
          });

          showToast("success", "Race Updated Succeesfully");
          this.getOneRace(this.props?.data?.item?._id);
          // this.props.getAllRace();
          // this.props.history.push("/race-list");
        } else {
          this.setState({
            loading: false,
          });
        }
      } else {
        const res = await createRace(formData);

        if (res) {
          this.setState({
            loading: false,
            countForRaceName: this.state.countForRaceName + 1,
          });

          this.props.history.push("/race-list");
          showToast("success", "Race Created Succeesfully");
        } else {
          this.setState({
            loading: false,
          });
        }
      }
    }
    // e.preventDefault();
  };

  handleCloneCreateRace = async () => {
    if (this.fieldValication()) {
      const formData = new FormData();

      if (this.props.history.location.isEdit) {
        formData.append(
          "name",
          this.state.name + "" + this.state.countForRaceName
        );
      } else {
        formData.append(
          "name",
          !this.state.isCreate
            ? this.state.name
            : this.state.name + "" + this.state.countForRaceName
        );
      }

      formData.append("startDay", this.state.startDate);
      formData.append("endDay", this.state.endDate);
      formData.append("group", this.state.group);
      formData.append("skin", this.state.skin);
      formData.append("min", this.state.minTeam);
      formData.append("max", this.state.maxTeam);
      formData.append("autoFinishRace", this.state.isAutoFinishRaceChecked);
      formData.append("leaderboard", this.state.toggleChecked);
      formData.append("pointsPerSeconds", this.state.pointSecond);
      formData.append("penaltyPerLocation", this.state.penaltyPerLocation);
      formData.append("pointsPerQustion", this.state.pointQuestion);
      formData.append("penaltyPerQustion", this.state.penaltyPerQuestion);
      formData.append("voucher", this.state.voucher);
      formData.append("registrationForm", this.state.options);
      formData.append("welcomeMessage", this.state.welcomeMessage);
      formData.append("finishMessage", this.state.finishMessage);
      formData.append("emailRegistrationForm", this.state.emailFrom);
      formData.append("emailRegistrationSubject", this.state.emailSubject);
      formData.append("emailRegistrationMessage", this.state.emailMessage);
      formData.append("registrationMessage", this.state.registrationMsg);
      formData.append("breifingText", this.state.raceBrefingDesc);
      // formData.append("checkInCode", this.state.codeLocation);
      // formData.append("checkInCodeArabic", this.state.codeLocationArabic);
      formData.append("raceLogo", this.state.uploadFile);
      formData.append("emailLogo", this.state.uploadEmailFile);
      formData.append("breifing", this.state.uploadMediaFile);
      formData.append("emergencyContact", this.state.emergencyContact);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      this.setState({
        cloneLoading: true,
      });
      const res = await createRace(formData);
      console.log("res: ", res);
      if (res) {
        this.setState({
          cloneLoading: false,
          countForRaceName: this.state.countForRaceName + 1,
          isCreate: true,
        });

        // this.props.history.push("/race-list");
        showToast("success", "Race Created Succeesfully");
      } else {
        this.setState({
          cloneLoading: false,
        });
      }
    }
  };

  config = {
    placeholder: "Enter message",
  };
  checkconfig = {
    placeholder: "Enter check-in-code At Location",
  };

  render() {
    return (
      <>
        <div className="ms-panel">
          <form>
            <div className="ms-panel-header">
              <h6 className="panel-title">Race Details</h6>
            </div>
            <div className="ms-panel-body">
              <div className="col-md-9">
                <div className="form-group row">
                  <label
                    htmlFor="exampleEmail"
                    className="col-sm-2 col-form-label col-form-label-sm"
                  >
                    Race Name
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleEmail"
                      placeholder="Race name"
                      value={this.state.name}
                      onChange={this.handleRaceName}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="startday"
                    className="col-sm-2 col-form-label col-form-label-sm"
                  >
                    Start Day/time
                  </label>
                  <div className="col-sm-7">
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={(date) => this.handleStartDate(date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      placeholderText="Select a weekday"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="endday"
                    className="col-sm-2 col-form-label col-form-label-sm"
                  >
                    End Day/time
                  </label>
                  <div className="col-sm-7">
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={(date) => this.handleEndDate(date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      minDate={this.state.startDate}
                      dateFormat="MM/dd/yyyy h:mm aa"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="group"
                    className="col-sm-2 col-form-label col-form-label-sm"
                  >
                    Group
                  </label>
                  <div className="col-sm-7">
                    <select
                      className="form-control"
                      id="group"
                      value={this.state.group}
                      onChange={this.handleGroup}
                    >
                      <option value={null}>Select Group</option>
                      {this.state.groupList.map((ele, indx) => {
                        return (
                          <option value={ele.id} key={indx.toString()}>
                            {ele.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="ms-panel-header"
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}
            >
              <h6 className="panel-title">Race Settings</h6>
            </div>
            <div className="ms-panel-body">
              <div className="col-md-9">
                <div className="form-group row">
                  <label
                    htmlFor="skin"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Skin
                  </label>
                  <div className="col-sm-7">
                    <select
                      className="form-control"
                      id="skin"
                      onChange={this.handleSkin}
                      value={this.state.skin}
                    >
                      <option value="">Select skin</option>
                      {this.state.skinList.map((ele, indx) => {
                        return (
                          <option value={ele.id} key={indx.toString()}>
                            {ele.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="teamsize"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Team Size
                  </label>
                  <div className="col-sm-7">
                    <div className="row">
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          name="min"
                          placeholder="Min"
                          value={this.state.minTeam}
                          onChange={this.handleTeamMin}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          name="max"
                          placeholder="Max"
                          value={this.state.maxTeam}
                          onChange={this.handleTeamMax}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="teamkey"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Domain
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="text"
                      className="form-control"
                      id="teamkey"
                      placeholder="Enter domain"
                      value={this.state.teamKey}
                      onChange={this.handleTeamKey}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="leaderboard"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Leaderboard
                  </label>
                  <div className="col-sm-7">
                    <Switch
                      className="react-switch"
                      onChange={this.handleToggleChange}
                      checked={this.state.toggleChecked}
                      handleDiameter={23}
                      // name="on"
                      // checkedIcon="On"
                      // uncheckedIcon="Off"
                      // width={70}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="pointsecond"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Points per second
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="number"
                      className="form-control"
                      id="pointsecond"
                      placeholder="Enter points per second"
                      value={this.state.pointSecond}
                      onChange={this.handlePointsSecond}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="penaltyperlocation"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Penalty per location
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="number"
                      className="form-control"
                      id="penaltyperlocation"
                      placeholder="Enter penalty per location"
                      value={this.state.penaltyPerLocation}
                      onChange={this.handlePenaltyPerLocation}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="pointsquestion"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Points per question
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="number"
                      className="form-control"
                      id="pointsquestion"
                      placeholder="Enter points per question"
                      value={this.state.pointQuestion}
                      onChange={this.handlePointsQuestion}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="penaltyperquestion"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Penalty per question
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="number"
                      className="form-control"
                      id="penaltyperquestion"
                      placeholder="Enter penalty per question"
                      value={this.state.penaltyPerQuestion}
                      onChange={this.handlePenaltyPerQuestion}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="racelogo"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Race Logo
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={this.uploadSingleFile}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="validatedCustomFile"
                    ></label>

                    {this.state.file && this.state.file.length > 0 ? (
                      <div className="form-group show-preview">
                        <img alt="img" src={this.state.file} />
                        <i
                          className="far fa-times-circle closeicon"
                          onClick={this.handleRemoveImg}
                        ></i>
                      </div>
                    ) : this.state.raceLogoImg ? (
                      <div className="form-group show-preview">
                        <i
                          className="far fa-times-circle closeicon"
                          onClick={this.handleRemovePropsImg}
                        ></i>
                        <img
                          alt="img"
                          src={IMAGE_URL + "" + this.state.raceLogoImg}
                        />
                      </div>
                    ) : (
                      <div className="form-group show-preview">
                        <img src={raceLogo} alt="img" />
                      </div>
                    )}

                    {/* {this.props.location.state ? (
                      <div className="form-group show-preview">
                        <img
                          src={
                            IMAGE_URL +
                            "" +
                            this.props.location.state.item.settings.raceLogo
                          }
                        />
                      </div>
                    ) : null} */}
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="emaillogo"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Email Logo
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={this.uploadEmailSingleFile}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="validatedCustomFile"
                    ></label>
                    {this.state.fileEmail && this.state.fileEmail.length > 0 ? (
                      <div className="form-group show-preview">
                        <img src={this.state.fileEmail} alt="img" />
                        <i
                          className="far fa-times-circle closeicon"
                          onClick={this.handleRemoveEmailImg}
                        ></i>
                      </div>
                    ) : this.state.emailLogoImg ? (
                      <div className="form-group show-preview">
                        <i
                          className="far fa-times-circle closeicon"
                          onClick={this.handleRemoveEmailPropsImg}
                        ></i>
                        <img
                          src={IMAGE_URL + "" + this.state.emailLogoImg}
                          alt="img"
                        />
                      </div>
                    ) : null}

                    {/* {this.props.location.state ? (
                      <div className="form-group show-preview">
                        <img
                          src={
                            IMAGE_URL +
                            "" +
                            this.props.location.state.item.settings.emailLogo
                          }
                        />
                      </div>
                    ) : null} */}
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="voucher"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Voucher
                  </label>
                  <div className="col-sm-7">
                    <select
                      className="form-control"
                      id="voucher"
                      onChange={this.handleVoucher}
                      value={this.state.voucher}
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <label className="ms-checkbox-wrap ms-checkbox-primary">
                    <input
                      type="checkbox"
                      name="finishrace"
                      checked={this.state.isAutoFinishRaceChecked}
                      onChange={this.handleAutoChange}
                    />
                    <i className="ms-checkbox-check" />
                  </label>
                  <span>
                    Automatically finish race when checkin last location?
                  </span>
                </div>
              </div>
            </div>
            <div
              className="ms-panel-header"
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}
            >
              <h6 className="panel-title">Registration Form</h6>
            </div>

            <div className="ms-panel-body">
              <div className="row">
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="ms-list ms-list-display d-flex flex-wrap">
                        {this.state.regFormData.length > 0
                          ? this.state.regFormData.map((item) => {
                              return (
                                <CheckboxField
                                  key={item.id}
                                  data={item}
                                  propsData={this.props.data}
                                  regFormData={this.state.regFormData}
                                  options={this.state.options}
                                  callbackOption={(data) => {
                                    this.handleOption(data);
                                  }}
                                />
                              );
                            })
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <Button
                    className="btn btn-pill redbutton"
                    onClick={this.handleModal}
                  >
                    + Add Fields
                  </Button>

                  <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <AddRegistrationField
                      handleClose={this.handleClose}
                      getAllResiField={this.getAllResiField}
                    />
                  </Modal>
                </div>
              </div>
            </div>

            <div
              className="ms-panel-header"
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}
            >
              <h6 className="panel-title">Messages</h6>
            </div>
            <div className="ms-panel-body">
              <div className="col-md-10">
                <div className="form-group row">
                  <label
                    htmlFor="welcomemessage"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Welcome message
                  </label>
                  <div className="col-sm-7">
                    <JoditEditor
                      config={this.config}
                      value={this.state.welcomeMessage}
                      // placeholder="dshgfsdhh"
                      tabIndex={1}
                      onChange={(newContent) =>
                        this.handleWelcomeMessage(newContent)
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="finishmessage"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Finish message
                  </label>
                  <div className="col-sm-7">
                    <JoditEditor
                      value={this.state.finishMessage}
                      config={this.config}
                      tabIndex={1}
                      onChange={(newContent) =>
                        this.handleFinishMessage(newContent)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="emailfrom"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Email registration form
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="text"
                      className="form-control"
                      id="emailfrom"
                      placeholder="Enter registration form"
                      value={this.state.emailFrom}
                      onChange={this.handleEmailFrom}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="emailsubject"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Email registration subject
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="text"
                      className="form-control"
                      id="emailsubject"
                      placeholder="Enter subject"
                      value={this.state.emailSubject}
                      onChange={this.handleEmailSubject}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="emailmessage"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Email registration message
                  </label>
                  <div className="col-sm-7">
                    <JoditEditor
                      value={this.state.emailMessage}
                      config={this.config}
                      tabIndex={1}
                      onChange={(newContent) =>
                        this.handleEmailMessage(newContent)
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="registrationmsg"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Race Briefing instruction
                  </label>
                  <div className="col-sm-7">
                    <JoditEditor
                      value={this.state.raceBrefingDesc}
                      config={this.config}
                      tabIndex={1}
                      onChange={(newContent) =>
                        this.handleBrefingDesc(newContent)
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="emergency"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Emergency Contact No.
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="number"
                      className="form-control"
                      id="emergency"
                      placeholder="Enter contact no."
                      value={this.state.emergencyContact}
                      onChange={this.handleEmergency}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label col-form-label-sm">
                    Race Briefing Media type
                  </label>
                  <div className="col-sm-8">
                    <ul
                      className="ms-list d-flex"
                      onChange={this.onChangeValue}
                    >
                      <li className="ms-list-item pl-0 pt-0">
                        <label className="ms-checkbox-wrap">
                          <input
                            type="radio"
                            name="radioExample"
                            value=""
                            checked={this.state.mediaType === ""}
                            // defaultChecked
                          />
                          <i className="ms-checkbox-check" />
                        </label>
                        <span> None </span>
                      </li>
                      <li className="ms-list-item pt-0">
                        <label className="ms-checkbox-wrap">
                          <input
                            type="radio"
                            name="radioExample"
                            value="image"
                            checked={this.state.mediaType === "image"}
                          />
                          <i className="ms-checkbox-check" />
                        </label>
                        <span> Image </span>
                      </li>
                      <li className="ms-list-item pt-0">
                        <label className="ms-checkbox-wrap">
                          <input
                            type="radio"
                            name="radioExample"
                            value="audio"
                            checked={this.state.mediaType === "audio"}
                          />
                          <i className="ms-checkbox-check" />
                        </label>
                        <span> Audio </span>
                      </li>
                      <li className="ms-list-item pt-0">
                        <label className="ms-checkbox-wrap">
                          <input
                            type="radio"
                            name="radioExample"
                            value="video"
                            checked={this.state.mediaType === "video"}
                          />
                          <i className="ms-checkbox-check" />
                        </label>
                        <span> Video </span>
                      </li>
                    </ul>
                  </div>
                </div>
                {this.state.mediaType === "image" ? (
                  <div className="form-group row">
                    <label
                      htmlFor="racemedia"
                      className="col-sm-3 col-form-label col-form-label-sm"
                    >
                      Race Briefing Media type
                    </label>
                    <div className="col-sm-7">
                      <input
                        type="file"
                        className="custom-file-input"
                        onChange={this.uploadMediaFile}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="validatedCustomFile"
                      ></label>
                      {this.state.mediaFile &&
                      this.state.mediaFile.length > 0 ? (
                        <div className="form-group edit-preview">
                          <i
                            className="fas fa-times-circle"
                            onClick={this.handleRemoveMedia}
                          ></i>
                          <img src={this.state.mediaFile} alt="img" />
                        </div>
                      ) : this.state.raceMediaImg &&
                        this.state.raceData.messages?.breifing?.mediaType ===
                          "image" ? (
                        <div className="form-group edit-preview">
                          <i
                            className="fas fa-times-circle"
                            onClick={this.handleRemoveMediaProps}
                          ></i>
                          <img
                            src={IMAGE_URL + "" + this.state.raceMediaImg}
                            alt="img"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {this.state.mediaType === "audio" ? (
                  <div className="form-group row">
                    <label
                      htmlFor="racemedia"
                      className="col-sm-3 col-form-label col-form-label-sm"
                    >
                      Race Briefing Media
                    </label>
                    <div className="col-sm-7">
                      <input
                        type="file"
                        className="custom-file-input"
                        onChange={this.uploadMediaFile}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="validatedCustomFile"
                      ></label>
                      {this.state.mediaFile &&
                      this.state.mediaFile.length > 0 ? (
                        <div className="form-group preview">
                          <i
                            className="fas fa-times-circle"
                            onClick={this.handleRemoveMedia}
                          ></i>
                          <audio controls>
                            <source
                              src={this.state.mediaFile}
                              type="audio/mpeg"
                            />
                          </audio>
                        </div>
                      ) : this.state.raceMediaImg &&
                        this.state.raceData.messages.breifing.mediaType ===
                          "audio" ? (
                        <div className="form-group preview">
                          <i
                            className="fas fa-times-circle"
                            onClick={this.handleRemoveMediaProps}
                          ></i>

                          <audio controls>
                            <source
                              src={IMAGE_URL + "" + this.state.raceMediaImg}
                              type="audio/mpeg"
                            />
                          </audio>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {this.state.mediaType === "video" ? (
                  <div className="form-group row">
                    <label
                      htmlFor="racemedia"
                      className="col-sm-3 col-form-label col-form-label-sm"
                    >
                      Race Briefing Media
                    </label>
                    <div className="col-sm-7">
                      <input
                        type="text"
                        className="form-control"
                        id="video url"
                        placeholder="Enter video url"
                        value={this.state.videoURL}
                        onChange={this.handleVideoUrl}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="form-group row">
                  <label
                    htmlFor="registrationmsg"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Terms and Conditions
                  </label>
                  <div className="col-sm-7">
                    <JoditEditor
                      value={this.state.registrationMsg}
                      config={this.config}
                      tabIndex={1}
                      onChange={(newContent) =>
                        this.handleRegistrationMsg(newContent)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {Object.keys(this.state.errors).map((key) => {
              return (
                <div key={key} className="error-box">
                  {this.state.errors[key]}
                </div>
              );
            })}
          </form>

          <Button
            type="button"
            className="redbutton create-race btn-pill"
            onClick={this.handleCreateRace}
          >
            {this.props?.data?.item ? "Update" : "Create Race"}
            {this.state.loading && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="ml-2"
              />
            )}
          </Button>

          {/* <Button
            type="button"
            className="redbutton create-race btn-pill"
            onClick={this.handleCloneCreateRace}
          >
            Clone Race
            {this.state.cloneLoading && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="ml-2"
              />
            )}
          </Button> */}
          {/* {!this.props?.data?.item ? (
            <Button
              type="button"
              className="redbutton create-race btn-pill"
              onClick={this.handleCloneCreateRace}
            >
              Clone Race
              {this.state.cloneLoading && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="ml-2"
                />
              )}
            </Button>
          ) : null} */}
        </div>
      </>
    );
  }
}

export default withRouter(AddRaceForm);
