import React, { Component } from "react";
import { connect } from "react-redux";

class SingleRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationOptions: [],
      routerName: this.props.allData?.name || "",
      locationArray: [],
      location: "",
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        locationArray: this.props.locationArray,
        location: this.props.data.location,
      });
    }, 1000);
  };

  componentDidUpdate = () => {
    setTimeout(() => {
      this.setState({
        location: this.props.data.location?.length
          ? this.props.data.location
          : "",
      });
    }, 1000);
  };

  locList = [];

  handleRaceName = (e) => {
    this.setState({
      routerName: e.targer.value,
    });
  };

  handleRouteChange = (e, index) => {
    this.props.handleChange(e.target.value);
    this.setState({
      location: e.target.value,
    });
  };
  render() {
    const { data, disable } = this.props;
    return (
      <>
        <div className="location-box" key={data.id.toString()}>
          <label htmlFor="location1">{`Location ${
            this.props.index + 1
          }`}</label>
          <br />

          <select
            className="custom-route-select"
            value={this.state.location}
            onChange={(val) => this.handleRouteChange(val, this.props.index)}
            disabled={disable}
          >
            {this.props.locations.map((option, i) => {
              return (
                <>
                  {option.finalLocation || option.startLocation ? (
                    <option value={option.id} disabled key={i.toString()}>
                      {data.location === option.id ? option.name : option.name}
                    </option>
                  ) : (
                    <option value={option.id} key={i.toString()}>
                      {data.location === option.id ? option.name : option.name}
                    </option>
                  )}
                </>
              );
            })}
          </select>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.LocationList.locationList,
    // fetching: state.LocationList.fetching,
    // routeLocations: state.RouteList.routeLocations,
  };
};
const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, null)(SingleRoute);
