import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Scrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import logo from "../../assets/logo.png";

class Sidenavigation extends Component {
  removeoverlay = () => {
    $(".ms-body").toggleClass("ms-aside-left-open");
    $("#ms-side-nav").toggleClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
  };
  componentDidMount() {
    function setActiveMenuItem() {
      $(".ms-main-aside .menu-item>a").on("click", function () {
        $(this).removeAttr("href");
        var element = $(this).parent("li");
        if (element.hasClass("active")) {
          element.removeClass("active");
          element.find("li").removeClass("active");
          element.find(".collapse").slideUp();
        } else {
          element.addClass("active");
          element.children(".collapse").slideDown();
          element.siblings("li").children(".collapse").slideUp();
          element.siblings("li").removeClass("active");
          element.siblings("li").find("li").removeClass("active");
          element.siblings("li").find(".collapse").slideUp();
        }
      });
    }
    setActiveMenuItem();
  }
  render() {
    return (
      <div>
        <div
          className="ms-aside-overlay ms-overlay-left ms-toggler"
          onClick={this.removeoverlay}
        ></div>
        <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
        <Scrollbar
          id="ms-side-nav"
          className="side-nav fixed ms-aside-scrollable ms-aside-left"
        >
          {/* Logo */}
          <div
            className="d-flex justify-content-between flex-column"
            style={{ height: "100%" }}
          >
            <div>
              <div className="logo-sn ms-d-block-lg">
                <Link className="pl-0 ml-0 text-center" to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              {/* Navigation */}
              <ul
                className="accordion ms-main-aside fs-14"
                id="side-nav-accordion"
              >
                {/* Dashboard */}
                <li className="menu-item">
                  <Link to="/">
                    <span>
                      <i className="material-icons fs-16">dashboard</i>Dashboard
                    </span>
                  </Link>
                </li>
                {/* /Dashboard */}

                {/* race*/}
                <li className="menu-item">
                  <Link to="#" className="has-chevron">
                    <span>
                      <i className="fas fa-user-friends fs-16" />
                      Races
                    </span>
                  </Link>
                  <ul
                    id="customer"
                    className="collapse"
                    aria-labelledby="customer"
                    data-parent="#side-nav-accordion"
                  >
                    <li>
                      <Link to="/race-list">Race List</Link>
                    </li>
                    <li>
                      <Link to="/add-race">Add a race</Link>
                    </li>
                    {/* <li>
                      <Link to="/view-edit-race">View/Edit Race</Link>
                    </li> */}
                  </ul>
                </li>
                {/* race  end */}

                {/* Basic UI Elements */}
                <li className="menu-item">
                  <Link to="#" className="has-chevron">
                    <span>
                      <i className="material-icons fs-16">filter_list</i>Race
                      Groups
                    </span>
                  </Link>
                  <ul
                    id="basic-elements"
                    className="collapse"
                    aria-labelledby="basic-elements"
                    data-parent="#side-nav-accordion"
                  >
                    <li>
                      <Link to="/group-list">Group List</Link>
                    </li>
                    <li>
                      <Link to="/group-race-list">Race List</Link>
                    </li>
                  </ul>
                </li>
                {/* /Basic UI Elements */}

                <li className="menu-item">
                  <Link to="#">
                    <span>
                      <i className="material-icons fs-16">format_paint</i>Race
                      Stats
                    </span>
                  </Link>
                </li>

                <li className="menu-item">
                  <Link to="#">
                    <span>
                      <i className="material-icons fs-16">format_paint</i>
                      Clients Data
                    </span>
                  </Link>
                </li>

                <li className="menu-item">
                  <Link to="#">
                    <span>
                      <i className="material-icons fs-16">format_paint</i>
                      Vendors Summary
                    </span>
                  </Link>
                </li>

                <li className="menu-item">
                  <Link to="#" className="has-chevron">
                    <span>
                      <i className="fas fa-user-friends fs-16" />
                      Skin
                    </span>
                  </Link>
                  <ul
                    id="basic-elements"
                    className="collapse"
                    aria-labelledby="basic-elements"
                    data-parent="#side-nav-accordion"
                  >
                    <li>
                      <Link to="/skin-list">Skin List</Link>
                    </li>
                    <li>
                      <Link to="/add-skin">Add Skin</Link>
                    </li>
                    <li>
                      <Link to="/race-skin-list">Race List</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div>
              <ul
                className="accordion ms-main-aside fs-14"
                id="side-nav-accordion"
              >
                <li className="menu-item">
                  <Link to="#">
                    <span>
                      <i className="material-icons fs-16">format_paint</i>Add
                      User/Admin
                    </span>
                  </Link>
                </li>

                <li className="menu-item">
                  <Link to="#">
                    <span>
                      <i className="material-icons fs-16">format_paint</i>
                      News
                    </span>
                  </Link>
                </li>

                <li className="menu-item">
                  <Link to="#">
                    <span>
                      <i className="material-icons fs-16">format_paint</i>
                      Clear Cache
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Scrollbar>
      </div>
    );
  }
}

export default Sidenavigation;
