import React, { Component } from "react";

import "react-perfect-scrollbar/dist/css/styles.css";

import { getAllRaceList } from "../../actions/race";
import { getResultData } from "../../actions/result";
import { getProgressReport } from "../../actions/progress";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

class Homecontent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      raceData: [],
      raceID: "",
      resultData: [],
      resultLoading: false,
      locationCountData: [],
      pointsData: [],
    };
  }

  componentDidMount() {
    this.getAllRace();
  }

  getPointReport = async (id) => {
    const data = await getProgressReport(id);

    const name = data.map((ele) => {
      return { name: ele.name, count: ele.count };
    });

    const points = data.map((ele) => {
      return { name: ele.name, points: ele.points };
    });

    this.setState({
      locationCountData: name,
      pointsData: points,
    });
  };

  getAllRace = async () => {
    this.setState({
      loading: true,
    });
    const res = await getAllRaceList();

    const resId = res ? res[0]?.id : "";
    if (res) {
      this.setState({
        raceData: res,
        loading: false,
      });
      if (resId) {
        this.handleChange(resId);
      }
      if (resId) {
        this.getPointReport(resId);
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleRaceChange = async (e) => {
    const id = e.target.value;
    this.handleChange(id);
    this.getPointReport(id);
  };

  handleChange = async (id) => {
    this.setState({
      raceID: id,
      resultLoading: true,
    });
    const resultdata = await getResultData(id);
    if (resultdata) {
      this.setState({
        resultData: resultdata,
        resultLoading: false,
      });
    } else {
      this.setState({
        resultLoading: false,
      });
    }
  };
  render() {
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <h1 className="db-header-title">Welcome, Admin</h1>
          </div>

          <div className="col-xl-12 col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-header new">
                <select
                  className="form-control"
                  id="exampleSelect"
                  value={this.state.raceID}
                  onChange={this.handleRaceChange}
                >
                  {this.state.raceData.map((item) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </select>
              </div>

              <div className="ms-panel-body table-responsive">
                {this.state.resultLoading ? (
                  <div className="spinner spinner-5" />
                ) : this.state.resultData.length > 0 ? (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Rank</th>
                        <th scope="col">Team Name</th>
                        <th scope="col">Team Number</th>
                        <th scope="col">Captain Name</th>
                        <th scope="col">Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.resultData.map((item, index) => {
                        return (
                          <tr key={index.toString()}>
                            <th scope="row">{index + 1}</th>
                            <td>{item.name}</td>
                            <td> {item.teamId}</td>
                            <td> {item.captain_name}</td>
                            <td> {item.score}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  "No Result Found"
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-body">
                <div style={{ width: "100%", height: 400 }}>
                  <ResponsiveContainer>
                    <ComposedChart
                      layout="vertical"
                      data={this.state.locationCountData}
                      margin={{
                        top: 50,
                        right: 20,
                        bottom: 20,
                        left: 20,
                      }}
                    >
                      <text
                        x={500 / 2}
                        y={20}
                        fill="black"
                        textAnchor="middle"
                        dominantBaseline="central"
                      >
                        <tspan fontSize="14">No Of Locations Completed</tspan>
                      </text>
                      <CartesianGrid stroke="#f5f5f5" />
                      <XAxis type="number" />
                      <YAxis dataKey="name" type="category" />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="count" barSize={30} fill="#EA0B0B" />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-body">
                <ResponsiveContainer width="100%" height={400}>
                  <ComposedChart
                    layout="vertical"
                    data={this.state.pointsData}
                    margin={{
                      top: 50,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <text
                      x={500 / 2}
                      y={20}
                      fill="black"
                      textAnchor="middle"
                      dominantBaseline="central"
                    >
                      <tspan fontSize="14">Points</tspan>
                    </text>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="points" barSize={30} fill="#EA0B0B" />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Homecontent;
