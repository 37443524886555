import React, { Component } from "react";
import Breadcrumb from "./Breadcrumb";
import { connect } from "react-redux";
import { getRaceBySkinList, getSkinList } from "./../../../redux/action/skin";
import moment from "moment";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skinId: "",
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.GetSkinList();
      if (
        this.props.skin &&
        this.props.skin.length > 0 &&
        this.props.skin[0]._id
      ) {
        this.props.GetRaceBySkinList(this.props.skin[0]?._id);
      }
    }, 1000);
  }

  handleSkinChange = (e) => {
    this.setState({
      skinId: e.target.value,
    });
    this.props.GetRaceBySkinList(e.target.value);
  };
  render() {
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />

            <div className="ms-panel">
              <div className="ms-panel-header new">
                <select
                  className="form-control"
                  id="exampleSelect"
                  value={this.state.skinId}
                  onChange={this.handleSkinChange}
                >
                  {this.props.skin.map((item) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </select>
              </div>
              <div className="ms-panel-body table-responsive">
                {this.props.fetching ? (
                  <div className="spinner spinner-5" />
                ) : this.props.raceList && this.props.raceList.length > 0 ? (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Race ID</th>
                        <th scope="col">Race Name</th>
                        <th scope="col">Created Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.raceList.map((ele) => {
                        return (
                          <tr>
                            <th>{ele.raceId}</th>
                            <td>{ele.name}</td>

                            <td>
                              {moment(ele.createdAt).format("MM/DD/YYYY")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ fontSize: "20px" }}
                  >
                    No race found for selected group
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    skin: state.SkinList.skinList,
    skinFetch: state.SkinList.fetching,
    raceList: state.RaceBySkin.raceSkinList,
    fetching: state.RaceBySkin.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetSkinList: () => dispatch(getSkinList()),
    GetRaceBySkinList: (id) => dispatch(getRaceBySkinList(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
