import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import icon from "../../../assets/icon.png";
import TeamStatusDetail from "./TeamStatusDetail";

import ProgressTeamModal from "./ProgressTeamModal";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { connect } from "react-redux";
import { getTeamList } from "./../../../redux/action/team";
import { getProgressReport } from "./../../../redux/action/report";

class Progress extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      locationCountData: [],
      pointsData: [],
      teamName: [],
      loading: false,
      resultData: [],
      allChecked: false,
      show: false,
      showStatus: false,
      singleTeamData: "",
      teamId: "",
    };
  }

  componentDidMount() {
    this.props.GetProgressReport(this.props?.data?.item?._id);
    this.props.GetTeamList(this.props?.data?.item?._id);
  }

  handleEditProgressModal = (data) => {
    this.setState({
      show: true,
      singleTeamData: data,
    });
  };

  handleEditProgressClose = () => {
    this.setState({
      show: false,
    });
  };

  TeamStatusModal = (data) => {
    this.setState({
      showStatus: true,
      singleTeamData: data,
    });
  };
  TeamStatusModalClose = () => {
    this.setState({
      showStatus: false,
    });
  };
  render() {
    return (
      <>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6">
            <ComposedChart
              layout="vertical"
              width={500}
              height={400}
              data={this.props.reports}
              margin={{
                top: 50,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <text
                x={500 / 2}
                y={20}
                fill="black"
                textAnchor="middle"
                dominantBaseline="central"
              >
                <tspan fontSize="14">No Of Locations Completed</tspan>
              </text>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" barSize={30} fill="#EA0B0B" />
            </ComposedChart>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6">
            <ComposedChart
              layout="vertical"
              width={500}
              height={400}
              data={this.props.reports}
              margin={{
                top: 50,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <text
                x={500 / 2}
                y={20}
                fill="black"
                textAnchor="middle"
                dominantBaseline="central"
              >
                <tspan fontSize="14">Points</tspan>
              </text>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" />
              <Tooltip />
              <Legend />
              <Bar dataKey="points" barSize={30} fill="#EA0B0B" />
            </ComposedChart>
          </div>
        </div>
        <div className="score-list mt-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive">
                <table className="table table-striped ">
                  <thead>
                    <tr>
                      <th>
                        <label className="ms-checkbox-wrap">
                          <input
                            type="checkbox"
                            name="checkAll"
                            checked={this.state.allChecked}
                            onClick={this.handleChange}
                          />
                          <i className="ms-checkbox-check" />
                        </label>
                      </th>
                      <th scope="col">Rank</th>
                      <th scope="col">Team Name</th>
                      <th scope="col">Captain Name</th>
                      <th scope="col">Score</th>
                      <th scope="col" className="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {this.props.fetching ? (
                    <div className="spinner spinner-5" />
                  ) : this.props.teams && this.props.teams.length > 0 ? (
                    <tbody>
                      {this.props.teams.map((item, indx) => {
                        console.log("item: ", item);
                        let scoreArray = item?.status?.map((x) => {
                          return x.points;
                        });

                        let score = 0;
                        scoreArray.forEach((element) => {
                          score = score + element;
                        });

                        return (
                          <tr key={indx.toString()}>
                            <th>
                              <label className="ms-checkbox-wrap">
                                <input
                                  key={item.id}
                                  type="checkbox"
                                  name={item.name}
                                  // value={item.name}
                                  checked={item.isChecked}
                                  onClick={this.handleChange}
                                />
                                <i className="ms-checkbox-check" />
                              </label>
                            </th>
                            <th scope="row">{indx + 1}</th>
                            <td>{item.name}</td>
                            <td>
                              {item.captain_name
                                ? item.captain_name
                                : item?.members[0]
                                ? item.members[0]?.name
                                : "-"}
                            </td>
                            <td>
                              {score}

                              {score < 0 ? (
                                <img
                                  src={icon}
                                  alt="img"
                                  className="alerticon"
                                />
                              ) : null}
                            </td>

                            <td className="text-center">
                              <Button
                                className="btn btn-pill redbutton mt-0 mr-2"
                                onClick={() =>
                                  this.handleEditProgressModal(item)
                                }
                              >
                                Edit
                              </Button>

                              <i
                                className="fas fa-plus fa-md"
                                onClick={() => this.TeamStatusModal(item)}
                                style={{ color: "#666674" }}
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                      <Modal
                        show={this.state.show}
                        size="lg"
                        onHide={this.handleEditProgressClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <ProgressTeamModal
                          singleData={this.state.singleTeamData}
                          handleEditProgressClose={this.handleEditProgressClose}
                          raceId={this.props?.data?.item?._id}
                        />
                      </Modal>

                      <Modal
                        show={this.state.showStatus}
                        size="lg"
                        onHide={this.TeamStatusModalClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header>
                          <h3 className="modal-title has-icon ms-icon-round ">
                            Team Detail
                          </h3>
                          <button
                            type="button"
                            className="close"
                            onClick={this.TeamStatusModalClose}
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </Modal.Header>
                        <Modal.Body>
                          <TeamStatusDetail data={this.state.singleTeamData} />
                        </Modal.Body>
                      </Modal>
                    </tbody>
                  ) : (
                    "No data found"
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.RouteList.routeList,
    teams: state.TeamList.teamList,
    fetching: state.TeamList.fetching,
    reports: state.ProgressReport.ProgressData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetTeamList: (id) => dispatch(getTeamList(id)),
    GetProgressReport: (id) => dispatch(getProgressReport(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
