import React, { Component } from "react";
import { Link } from "react-router-dom";

class Breadcrumb extends Component {
  render() {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="heading-title">Group by Race</h4>

          <Link to="/add-race">
            <button
              type="button"
              className="btn btn-pill btn-primary m-0 redbutton"
            >
              + Add A Race
            </button>
          </Link>
        </div>
      </>
    );
  }
}

export default Breadcrumb;
