import { showToast } from "../common/ToastMsg";
import { apiInstance } from "./../../httpClient/index";

export const getTeamList = async (id) => {
  try {
    const res = await apiInstance.get(`team/race/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const createTeamList = async (teamBody) => {
  try {
    const res = await apiInstance.post(`team/create`, teamBody);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const createBulkTeam = async (teamData) => {
  try {
    const res = await apiInstance.post(`team/bulk-create`, teamData);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const updateTeamList = async (singleTeamData, teamBody) => {
  try {
    const res = await apiInstance.put(
      `team/update/${singleTeamData}`,
      teamBody
    );
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const deleteTeamData = async (data) => {
  try {
    const res = await apiInstance.delete(`team/remove/${data}`);
    if (res.status === 200) {
      return res;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const getTeamStatusData = async (id) => {
  try {
    const res = await apiInstance.get(`team/status/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const getSingleTeamData = async (id) => {
  try {
    const res = await apiInstance.get(`team/get-one/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};
