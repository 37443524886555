import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { getSingleTeamData, updateTeamList } from "../../actions/team";
import { showToast } from "../../common/ToastMsg";
import { getTeamList } from "./../../../redux/action/team";
import { getData } from "./../../../utils/common";

class ProgressTeamModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      setLoading: false,
      teamData: [],
      userId: "",
    };
  }

  componentDidMount() {
    if (this.props?.singleData?.id) {
      this.getSingleTeam(this.props.singleData.id);
      this.setState({
        userId: getData().id,
      });
    }
  }

  getSingleTeam = async (id) => {
    this.setState({
      loading: true,
    });
    const res = await getSingleTeamData(id);
    if (res) {
      this.setState({
        teamData: res,
        teamName: res.name,
        email: res.email,
        captainName: res.captain_name,
        routeName: res.route.id,
        loading: false,
        password: res.password,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleUpdateTeam = async () => {
    const teamBody = {
      race: this.props?.raceId,
      name: this.state.teamName,
      captain_name: this.state.captainName,
      route: this.state.routeName,
      password: this.state.password,
      user: this.state.userId,
    };
    this.setState({
      setLoading: true,
    });
    const res = await updateTeamList(this.state.teamData.id, teamBody);
    if (res) {
      this.setState({
        setLoading: false,
      });
      showToast("success", "Team updated successfully");
      this.props.handleEditProgressClose();
      this.props.GetTeamList(this.props?.raceId);
    } else {
      this.setState({
        setLoading: false,
      });
    }
  };

  handleTeamName = (e) => {
    this.setState({
      teamName: e.target.value,
    });
  };
  handleCaptainName = (e) => {
    this.setState({
      captainName: e.target.value,
    });
  };
  handleRoute = (e) => {
    this.setState({
      routeName: e.target.value,
    });
  };

  handlePassword = (e) => {
    console.log("e: ", e);
    this.setState({
      password: e.target.value,
    });
  };

  handleidType = () => {
    this.setState({
      idType: !this.state.idType,
    });
  };

  handleEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  render() {
    return (
      <>
        <Modal.Header>
          <h3 className="modal-title has-icon ms-icon-round ">Edit Teams</h3>
          <button
            type="button"
            className="close"
            onClick={this.props.handleEditProgressClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {this.state.loading ? (
            <div className="spinner spinner-5" />
          ) : (
            <>
              <form>
                <div className="form-group">
                  <label htmlFor="teamName">Team name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="teamName"
                    placeholder="Team name"
                    value={this.state.teamName}
                    onChange={this.handleTeamName}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="captainname">Captain name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="captainname"
                    placeholder="captain name"
                    value={this.state.captainName}
                    onChange={this.handleCaptainName}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="teamName">Route</label>
                  <select
                    className="form-control"
                    id="group"
                    value={this.state.routeName}
                    onChange={this.handleRoute}
                  >
                    {this.props.routes.map((ele, i) => {
                      return (
                        <>
                          <option value={ele.id} key={i.toString()}>
                            {this.state.teamData.route?.id === ele.id
                              ? ele.name
                              : ele.name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email Id</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    id="email"
                    placeholder="Enter Email"
                    value={this.state.email}
                    onChange={this.handleEmail}
                  />
                </div>

                {this.props?.isEdit !== "Add" ? (
                  <>
                    <div className="form-group">
                      <label htmlFor="email">Password</label>
                      <input
                        // disabled={this.props?.isEdit !== "Add"}
                        className="form-control"
                        id="password"
                        placeholder="Update Password"
                        value={this.state.password}
                        onChange={this.handlePassword}
                      />
                    </div>
                  </>
                ) : null}
              </form>
              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-light btn-pill mr-2"
                  onClick={this.props.handleEditProgressClose}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary redbutton btn-pill"
                  onClick={this.handleUpdateTeam}
                >
                  Update
                  {this.state.setLoading && (
                    <Spinner
                      animation="border"
                      variant="light"
                      size="sm"
                      className="ml-2"
                    />
                  )}
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.RouteList.routeList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetTeamList: (id) => dispatch(getTeamList(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressTeamModal);
