import { apiInstance } from "../../httpClient";
import * as actions from "./index";

export const getProgressReport = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.getProgressReportRequest());

    apiInstance
      .get(`progress/${id}`)
      .then((res) => {
        dispatch(actions.getProgressReportSuccess(res.data.data));
        resolve();
      })
      .catch((e) => {
        console.log("e: ", e.response);
        dispatch(actions.getProgressReportError(e?.response?.data?.message));
        reject();
      });
  });
