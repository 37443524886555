import React, { Component } from "react";
import { getToken } from "../../utils/common";
import Footer from "../layouts/Footer";
import Sidenavigation from "../layouts/Sidenavigation";
import Topnavigation from "../layouts/Topnavigation";
import Content from "../sections/Add-skin/Content";

class AddSkin extends Component {
  componentDidMount() {
    if (getToken() === null) {
      this.props.history.push("/login");
    }
  }
  render() {
    return (
      <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
        <Sidenavigation />
        <main className="body-content">
          <Topnavigation />
          <Content />
          <Footer />
        </main>
      </div>
    );
  }
}

export default AddSkin;
