import React, { Component } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { deleteTeamData } from "./../../actions/team";
import { connect } from "react-redux";
import { getTeamList } from "./../../../redux/action/team";
import { showToast } from "../../common/ToastMsg";
import { getProgressReport } from "../../../redux/action/report";

class DeleteTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  handleTeamDelete = async () => {
    this.setState({
      loading: true,
    });

    const res = await deleteTeamData(this.props.singleTeamDataDelete);
    if (res) {
      this.setState({
        loading: false,
      });
      this.props.handleTeamDeleteClose();
      showToast("success", "Team deleted successfully");
      this.props.GetProgressReport(this.props?.data?.item?._id);
      this.props.GetTeamList(this.props.data?.item?._id);
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  render() {
    return (
      <>
        <Modal.Body>
          <h3>Are you sure you want to delete?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-light btn-pill"
            onClick={this.props.handleTeamDeleteClose}
          >
            No
          </Button>
          <Button
            className="btn btn-primary btn-pill redbutton"
            onClick={this.handleTeamDelete}
          >
            Yes
            {this.state.loading && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="ml-2"
              />
            )}
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teamFetching: state.TeamList.fetching,
    teams: state.TeamList.teamList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetProgressReport: (id) => dispatch(getProgressReport(id)),
    GetTeamList: (id) => dispatch(getTeamList(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTeam);
