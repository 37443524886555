import React, { Component } from "react";
import Breadcrumb from "./Breadcrumb";
import SearchBar from "../../common/SearchBar";
import { Link } from "react-router-dom";
import DeleteRace from "./DeleteRace";
import { connect } from "react-redux";
import moment from "moment";
import { getRaceList } from "./../../../redux/action/race";
import ReactPaginate from "react-paginate";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allChecked: false,
      loading: false,
      offset: 0,
      currentPage: 0,
      postRace: [],
    };
  }

  componentDidMount() {
    this.props.GetRaceList("");
    setTimeout(() => {
      this.receivedData();
    }, 1000);
  }

  receivedData = () => {
    if (this.props.race && this.props.race.length > 0) {
      const data = this.props.race;
      const slice = data.slice(this.state.offset, this.state.offset + 10);
      const postrace = slice.map((item, index) => (
        <>
          <tr key={item.id.toString()}>
            <th scope="row">{index + 1}</th>
            <th scope="row" className="mobileView">
              {item.raceId}
            </th>
            <td>
              <Link
                to={{
                  pathname: "/view-edit-race",
                  state: { item },
                  isEdit: true,
                }}
                style={{ color: "#212529", textDecoration: "underline" }}
              >
                {item.name}
              </Link>
            </td>
            <td className="mobileView">
              {moment(item.startDay).format("DD/MM/YYYY hh:ss A")}
            </td>
            <td className="mobileView">
              {moment(item.createdAt).format("DD/MM/YYYY")}
            </td>
            <td className="mobileView">
              {moment(item.updatedAt).format("DD/MM/YYYY")}
            </td>

            <td>
              <Link
                to={{
                  pathname: "/view-edit-race",
                  state: { item },
                  isEdit: true,
                }}
              >
                <i
                  className="fas fa-edit fa-md"
                  style={{ color: "#878793" }}
                ></i>
              </Link>
              {/* <DeleteRace data={item} getAllRace={this.getAllRace} /> */}
            </td>
          </tr>
        </>
      ));
      this.setState({
        pageCount: Math.ceil(data.length / 10),
        postrace,
      });
    }
  };

  handlePageClick = async (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * 10;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData();
      }
    );
  };

  // handleChange = (e) => {
  //   let itemName = e.target.name;
  //   let checked = e.target.checked;
  //   let race1 = this.props.race;
  //   this.setState((prevState) => {
  //     let { race1, allChecked } = prevState;
  //     if (itemName === "checkAll") {
  //       allChecked = checked;
  //       race1 = race1.map((item) => ({
  //         ...item,
  //         isChecked: checked,
  //       }));
  //     } else {
  //       race1 = race1.map((item) =>
  //         item.name === itemName ? { ...item, isChecked: checked } : item
  //       );
  //       allChecked = race1.every((item) => item.isChecked);
  //     }
  //     return { race1, allChecked };
  //   });
  // };

  handleSearch = async (e) => {
    const val = e.target.value;
    this.props.GetRaceList(val);
    setTimeout(() => {
      this.receivedData();
    }, 1000);
  };

  render() {
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />

            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>Race List</h6>
              </div>
              <div className="ms-panel-body">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div className="form-group d-flex align-items-center">
                    <label htmlFor="exampleSelect" className="mb-0 pr-2">
                      Action
                    </label>
                    <div className="filter-search-box">
                      <select
                        className="form-control custom-select"
                        onChange={this.props.categorySelect}
                      >
                        <option value="">Select</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                      </select>
                    </div>
                  </div>
                  <SearchBar handleSearch={this.handleSearch} />
                </div>
                <div className="table-responsive">
                  {/* <table id="data-table-4" className="table w-100 thead-primary" /> */}
                  {this.props.raceFetch ? (
                    <div className="spinner spinner-5" />
                  ) : (
                    <table className="table table-striped ">
                      <thead>
                        <tr>
                          <th scope="col">SN</th>
                          <th scope="col" className="mobileView">
                            Race ID
                          </th>
                          <th scope="col">Race Name</th>
                          <th scope="col" className="mobileView">
                            Race Start Date
                          </th>
                          <th scope="col" className="mobileView">
                            Date Created
                          </th>
                          <th scope="col" className="mobileView">
                            Date Modified
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>{this.state.postrace}</tbody>
                    </table>
                  )}
                  <ReactPaginate
                    previousLabel={"«"}
                    nextLabel={"»"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    race: state.RaceList.raceList,
    raceFetch: state.RaceList.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetRaceList: (val) => dispatch(getRaceList(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
