import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer-section d-flex justify-content-between align-items-center">
        <p>2021 © Rush-A-Way. All Rights Reserved</p>
        <p>2021 </p>
      </div>
    );
  }
}
