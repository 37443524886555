import { showToast } from "../common/ToastMsg";
import { apiInstance } from "./../../httpClient/index";

export const getResultData = async (id) => {
  try {
    const res = await apiInstance.get(`result/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const getReviewsList = async (id) => {
  try {
    const res = await apiInstance.get(`feedback/get-all?raceId=${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const getDiscussionReviews = async (id) => {
  try {
    const res = await apiInstance.get(`question/get-all?raceId=${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};
