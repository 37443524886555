import { apiInstance } from "../../httpClient";
import * as actions from "./index";

export const getGroupList = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.getGroupListRequest());
    apiInstance
      .get("group/get-all")
      .then((res) => {
        dispatch(actions.getGroupListSuccess(res.data.data));
        resolve();
      })
      .catch((e) => {
        dispatch(actions.getGroupListError(e?.response?.data?.message));
        reject();
      });
  });

export const getRaceByGroupList = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.getRaceByGroupListRequest());
    apiInstance
      .get(`race/group/${id}`)
      .then((res) => {
        dispatch(actions.getRaceByGroupListSuccess(res.data.data));
        resolve();
      })
      .catch((e) => {
        dispatch(actions.getRaceByGroupListError(e?.response?.data?.message));
        reject();
      });
  });
