import { showToast } from "../common/ToastMsg";
import { apiInstance } from "./../../httpClient/index";

// export const getLocationList = async (id) => {
//   try {
//     const res = await apiInstance.get(`locations/race/${id}`);
//     if (res.status === 200) {
//       return res.data.data;
//     }
//     return null;
//   } catch (error) {
//     // showToast("error", error.response?.data?.message);
//     return null;
//   }
// };

export const updateLocation = async (id, formData) => {
  try {
    const res = await apiInstance.put(`locations/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const locationCreate = async (formData) => {
  try {
    const res = await apiInstance.post(`locations`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.status === 200) {
      return res.data.data;
    } else {
      return null;
    }
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const deleteLocation = async (id) => {
  try {
    const res = await apiInstance.delete(`locations/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};
