import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
// import { getLocationList } from "../../actions/location";
// import LocationList from "./LocationList";
import { createRoute } from "./../../actions/routes";
import { connect } from "react-redux";
import { showToast } from "../../common/ToastMsg";
import { getRouteList } from "./../../../redux/action/route";

class AddRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      routename: "",
      location1: "",
      loading: false,
      sortLocationList: [],
      LocationList: [],
      newList: [],
      errors: {},
      routeLocation: this.props.locations,
    };
  }

  locList = [];
  newArrLoc = this.props.locations;

  handlerouteName = (e) => {
    this.setState({
      routename: e.target.value,
    });
  };

  handleRouteChange = (e, index) => {
    const id = e.target.value;

    if (id && index > -1) {
      if (this.locList.some((val) => val.locationNumber === index + 1)) {
        this.locList[index] = {
          locationId: id,
          locationNumber: index + 1,
        };
      } else {
        this.locList.push({
          locationId: id,
          locationNumber: index + 1,
        });
      }
    }

    const sortLocList = this.locList.sort((a, b) =>
      a.locationNumber > b.locationNumber
        ? 1
        : b.locationNumber > a.locationNumber
        ? -1
        : 0
    );

    const newLocList = sortLocList.map((ele) => {
      return ele.locationId;
    });
    this.setState({
      sortLocationList: newLocList,
    });
  };

  fieldValication = () => {
    let isValid = true;
    const errors = {};
    if (this.state.routename === "") {
      errors.routename = "Required!!!";
      isValid = false;
    }

    this.setState({
      errors,
    });
    return isValid;
  };

  handleCreateRoute = async () => {
    if (this.fieldValication()) {
      this.setState({
        loading: false,
      });
      const routeBody = {
        race: this.props?.data?.item?._id,
        name: this.state.routename,
      };
      try {
        const res = await createRoute(routeBody);
        if (res) {
          this.setState({
            loading: false,
          });
          this.props.handleClose();
          showToast("success", "Route added successfully");
          this.props.GetRouteList(this.props?.data?.item?._id);
        } else {
          this.setState({
            loading: false,
          });
        }
      } catch (e) {
        console.log("e: ", e.response);
      }
    }
  };

  render() {
    return (
      <>
        <Modal.Header>
          <h3 className="modal-title has-icon ms-icon-round ">Add Route</h3>
          <button
            type="button"
            className="close"
            onClick={this.props.handleClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <form method="post">
            <div className="ms-form-group has-icon">
              <label>Route Name</label>
              <input
                type="text"
                placeholder="Enter name"
                className="form-control"
                name="routename"
                value={this.state.routename}
                onChange={this.handlerouteName}
              />
            </div>
            {Object.keys(this.state.errors).map((key) => {
              return (
                <div key={key} className="error-box">
                  {this.state.errors[key]}
                </div>
              );
            })}

            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-light mr-2 btn-pill"
                onClick={this.props.handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary shadow-none redbutton btn-pill"
                onClick={this.handleCreateRoute}
              >
                Add
                {this.state.loading && (
                  <Spinner
                    animation="border"
                    variant="light"
                    size="sm"
                    className="ml-2"
                  />
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    locations: state.LocationList.locationList,
    fetching: state.LocationList.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetRouteList: (id) => dispatch(getRouteList(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddRoute);
