import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
import { getReviewList } from "./../../../redux/action/reviews";
import { connect } from "react-redux";

class TeamReviewQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [],
    };
  }

  componentDidMount = () => {
    this.props.GetReviewList(this.props.data?.item?.id);
  };

  render() {
    return (
      <>
        <Accordion className="has-gap ms-accordion-chevron">
          {this.props.reviews && this.props.reviews.length > 0
            ? this.props.reviews.map((data, index) => {
                return (
                  <>
                    {data?.teamId?.name &&
                    data.questionReview &&
                    data?.questionReview?.length > 0 ? (
                      <>
                        <Card key={index.toString()}>
                          <Accordion.Collapse
                            className="collapseparent"
                            eventKey={index.toString()}
                          >
                            <Card.Body>
                              {data?.questionReview?.map((item, index) => {
                                return (
                                  <>
                                    {item.question && item.question.question ? (
                                      <Card>
                                        <Card.Body>
                                          <Card.Title>
                                            {item.question.question}
                                          </Card.Title>
                                          <Card.Text>
                                            {item?.answer
                                              ? item?.answer
                                              : item?.ans}
                                          </Card.Text>
                                        </Card.Body>
                                      </Card>
                                    ) : null}
                                  </>
                                );
                              })}
                            </Card.Body>
                          </Accordion.Collapse>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={index.toString()}
                          >
                            <span>{data?.teamId?.name}</span>
                          </Accordion.Toggle>
                        </Card>
                      </>
                    ) : null}
                  </>
                );
              })
            : "No Data Found"}
        </Accordion>
        {/* <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Team Name</th>
              <th scope="col">Question</th>
              <th scope="col">Ans</th>
            </tr>
          </thead>
          <tbody>
            {this.props.reviews && this.props.reviews.length > 0
              ? this.props.reviews.map((data, index) => {
                  return (
                    <tr key={index.toString()}>
                      {data.questionReview &&
                      data?.questionReview?.length > 0 ? (
                        <>
                          <td>{data?.teamId?.name}</td>
                        </>
                      ) : null}

                      {data?.questionReview && data?.questionReview?.length > 0
                        ? data?.questionReview?.map((item) => {
                            return (
                              <>
                                <td>{item?.question ? item?.question : "-"}</td>
                                <td>{item?.answer ? item?.answer : "-"}</td>
                              </>
                            );
                          })
                        : null}
                    </tr>
                  );
                })
              : "No Review Found!!"}
          </tbody>
        </table> */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    reviews: state.ReviewList.reviewList,
    fetching: state.ReviewList.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetReviewList: (id) => dispatch(getReviewList(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamReviewQuestions);
