import React, { Component } from "react";

export default class CheckboxField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFieldChecked: false,
      checkIsRegFormData: [],
    };
  }

  componentDidMount() {
    // this.handleRegistrationForm();
  }
  handleRegistrationForm = () => {
    let newArray = this.props?.propsData?.item?.registrationForm?.map(
      (item) => item.id
    );
    this.setState({
      checkIsRegFormData: newArray,
    });
  };

  handleOnChange = (value) => {
    this.props.callbackOption(this.props?.data);
    this.setState({
      isFieldChecked: value,
    });
  };

  render() {
    return (
      <>
        <li style={{ width: "33.3333%" }}>
          <label className="ms-checkbox-wrap ms-checkbox-primary">
            <input
              type="checkbox"
              name={this.props?.data?.name}
              checked={this.props.options?.includes(this.props?.data?.id)}
              onChange={() => this.handleOnChange(!this.state.isFieldChecked)}
            />
            <i className="ms-checkbox-check" />
          </label>
          <span> {this.props.data.name}</span>
        </li>
      </>
    );
  }
}
