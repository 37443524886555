import React, { Component } from "react";
import { Button, Spinner } from "react-bootstrap";
import { showToast } from "../../common/ToastMsg";
import { withRouter } from "react-router-dom";
import ColorPicker from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import { CreateSkin, updateSkinList } from "../../actions/skin";

export class AddRaceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      skinName: this.props?.location?.state?.item?.name || "",
      color1: this.props?.location?.state?.item?.skin[0] || "#8a8a8a",
      color2: this.props?.location?.state?.item?.skin[1] || "#f00",
      color3: this.props?.location?.state?.item?.skin[2] || "#51951F",
      color4: this.props?.location?.state?.item?.skin[3] || "#1F3B95",
      color5: this.props?.location?.state?.item?.skin[4] || "#C443BA",
      loading: false,
      isEdit: false,
    };
  }

  handleSkinName = (e) => {
    this.setState({
      skinName: e.target.value,
    });
  };

  changeHandler1 = (colors) => {
    this.setState({
      color1: colors.color,
    });
  };

  changeHandler2 = (colors) => {
    this.setState({
      color2: colors.color,
    });
  };

  changeHandler3 = (colors) => {
    this.setState({
      color3: colors.color,
    });
  };

  changeHandler4 = (colors) => {
    this.setState({
      color4: colors.color,
    });
  };

  changeHandler5 = (colors) => {
    this.setState({
      color5: colors.color,
    });
  };

  handleCreateSkin = async () => {
    let colorList = [
      this.state.color1,
      this.state.color2,
      this.state.color3,
      this.state.color4,
      this.state.color5,
    ];
    const skinBody = {
      name: this.state.skinName,
      skin: colorList,
    };
    this.setState({
      loading: true,
    });
    if (this.props?.location?.state?.item) {
      const res = await updateSkinList(
        this.props.location?.state?.item?._id,
        skinBody
      );
      if (res) {
        this.setState({
          loading: false,
        });
        showToast("success", "Skin updated successfully");
        this.props.history.push("/skin-list");
      } else {
        this.setState({
          loading: false,
        });
      }
    } else {
      const res = await CreateSkin(skinBody);

      if (res) {
        this.setState({
          loading: false,
        });
        showToast("success", "Skin added successfully");
        this.props.history.push("/skin-list");
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  };

  render() {
    return (
      <>
        <div className="ms-panel">
          <div className="ms-panel-body">
            <form>
              <form>
                <div className="form-group">
                  <label htmlFor="groupname">Group Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="groupname"
                    placeholder="Enter name"
                    value={this.state.skinName}
                    onChange={this.handleSkinName}
                  />
                </div>

                <div className="form-group">
                  <div className="color-picker-box">
                    <div>
                      <label htmlFor="color1">Color 1</label>
                      <ColorPicker
                        disabled={true}
                        className="colorpicker"
                        animation="slide-up"
                        color={this.state.color1}
                        onChange={this.changeHandler1}
                      />
                    </div>

                    <div>
                      <label htmlFor="color1">Color 2</label>
                      <ColorPicker
                        className="colorpicker"
                        animation="slide-up"
                        color={this.state.color2}
                        onChange={this.changeHandler2}
                      />
                    </div>

                    <div>
                      <label htmlFor="color1">Color 3</label>
                      <ColorPicker
                        className="colorpicker"
                        animation="slide-up"
                        color={this.state.color3}
                        onChange={this.changeHandler3}
                      />
                    </div>

                    <div>
                      <label htmlFor="color1">Color 4</label>
                      <ColorPicker
                        className="colorpicker"
                        animation="slide-up"
                        color={this.state.color4}
                        onChange={this.changeHandler4}
                      />
                    </div>

                    <div>
                      <label htmlFor="color1">Color 5</label>
                      <ColorPicker
                        className="colorpicker"
                        animation="slide-up"
                        color={this.state.color5}
                        onChange={this.changeHandler5}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </form>
          </div>

          <Button
            type="button"
            className="redbutton create-race btn-pill"
            onClick={this.handleCreateSkin}
          >
            {this.props?.location?.state?.item ? "Edit Skin" : "Add Skin"}

            {this.state.loading && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="ml-2"
              />
            )}
          </Button>
        </div>
      </>
    );
  }
}

export default withRouter(AddRaceForm);
