import React, { Component } from "react";
import Breadcrumb from "./Breadcrumb";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";

import "react-datetime/css/react-datetime.css";

import { withRouter } from "react-router-dom";
import AddSkinForm from "./AddSkinForm";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      races: [],
    };
  }

  render() {
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />
            <AddSkinForm />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Content);
