import {
  GET_ROUTE_LIST_REQUEST,
  GET_ROUTE_LIST_SUCCESS,
  SET_ROUTE_LOCATIONS,
  GET_ROUTE_LIST_ERROR,
} from "../../utils/constant";
const initialState = {
  fetching: false,
  routeList: [],
  routeLocations: [],
  error: {},
};

export const RouteList = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROUTE_LIST_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_ROUTE_LIST_SUCCESS:
      return {
        ...state,
        routeList: action.payload,
        fetching: false,
      };
    case GET_ROUTE_LIST_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    case SET_ROUTE_LOCATIONS:
      return {
        ...state,

        routeLocations: action.payload,
      };
    default:
      return state;
  }
};
