import React, { Component } from "react";
import Breadcrumb from "./Breadcrumb";
import { Link } from "react-router-dom";

import { getSkinList } from "../../actions/skin";
import DeleteSkin from "./DeleteSkin";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skinList: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.getAllSkin();
  }

  getAllSkin = async () => {
    this.setState({
      loading: true,
    });
    const res = await getSkinList();
    if (res) {
      this.setState({
        skinList: res,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />

            <div className="ms-panel">
              <div className="ms-panel-header d-flex justify-content-between align-items-center">
                <h6>Skin List</h6>
                <Link to="/add-skin">
                  <button
                    type="button"
                    className="btn btn-pill btn-primary m-0 redbutton"
                  >
                    + Add Skin
                  </button>
                </Link>
              </div>

              <div className="ms-panel-body">
                <div className="table-responsive">
                  {/* <table id="data-table-4" className="table w-100 thead-primary" /> */}
                  {this.state.loading ? (
                    <div className="spinner spinner-5" />
                  ) : (
                    <table className="table table-striped ">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          {/* <th scope="col">Skin</th> */}

                          <th scope="col" className="text-right">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.skinList.length > 0 ? (
                          this.state.skinList.map((item) => {
                            return (
                              <tr key={item.id.toString()}>
                                <td>{item.name}</td>
                                {/* <td>{item.name}</td> */}

                                <td className="text-right">
                                  <Link
                                    to={{
                                      pathname: "/add-skin",
                                      state: { item },
                                    }}
                                  >
                                    <i
                                      className="fas fa-edit fa-md"
                                      style={{ color: "#878793" }}
                                    ></i>
                                  </Link>

                                  <DeleteSkin
                                    data={item}
                                    getAllSkin={this.getAllSkin}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={2} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
