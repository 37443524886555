import React, { Component } from "react";

export default class Results extends Component {
  render() {
    return (
      <>
        <div className="table-responsive">
          {/* <table id="data-table-4" className="table w-100 thead-primary" /> */}
          {this.props.resultLoading ? (
            <div className="spinner spinner-5" />
          ) : (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Rank</th>
                  <th scope="col">Team Name</th>
                  <th scope="col">Team Number</th>
                  <th scope="col">Captain Name</th>
                  <th scope="col">Score</th>
                </tr>
              </thead>
              <tbody>
                {this.props.resultData.length > 0 ? (
                  this.props.resultData.map((item, index) => {
                    return (
                      <tr key={index.toString()}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.name}</td>
                        <td>{item.teamId}</td>
                        <td>{item.captain_name ? item.captain_name : "-"}</td>
                        <td>{item.score}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Result Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </>
    );
  }
}
