// export const BASE_URL = "https://tm-adu.herokuapp.com/v1/";
// export const BASE_URL = "http://192.168.0.106:5000/v1/";

export const GET_RACE_LIST_REQUEST = "GET_RACE_LIST_REQUEST";
export const GET_RACE_LIST_SUCCESS = "GET_RACE_LIST_SUCCESS";
export const GET_RACE_LIST_ERROR = "GET_RACE_LIST_ERROR";

export const GET_LOCATION_LIST_REQUEST = "GET_LOCATION_LIST_REQUEST";
export const GET_LOCATION_LIST_SUCCESS = "GET_LOCATION_LIST_SUCCESS";
export const GET_LOCATION_LIST_ERROR = "GET_LOCATION_LIST_ERROR";

export const GET_ROUTE_LIST_REQUEST = "GET_ROUTE_LIST_REQUEST";
export const GET_ROUTE_LIST_SUCCESS = "GET_ROUTE_LIST_SUCCESS";
export const GET_ROUTE_LIST_ERROR = "GET_ROUTE_LIST_ERROR";

export const GET_GROUP_LIST_REQUEST = "GET_GROUP_LIST_REQUEST";
export const GET_GROUP_LIST_SUCCESS = "GET_GROUP_LIST_SUCCESS";
export const GET_GROUP_LIST_ERROR = "GET_GROUP_LIST_ERROR";

export const GET_RACEBYGROUP_LIST_REQUEST = "GET_RACEBYGROUP_LIST_REQUEST";
export const GET_RACEBYGROUP_LIST_SUCCESS = "GET_RACEBYGROUP_LIST_SUCCESS";
export const GET_RACEBYGROUP_LIST_ERROR = "GET_RACEBYGROUP_LIST_ERROR";

export const GET_SKIN_LIST_REQUEST = "GET_SKIN_LIST_REQUEST";
export const GET_SKIN_LIST_SUCCESS = "GET_SKIN_LIST_SUCCESS";
export const GET_SKIN_LIST_ERROR = "GET_SKIN_LIST_ERROR";

export const GET_RACEBYSKIN_LIST_REQUEST = "GET_RACEBYSKIN_LIST_REQUEST";
export const GET_RACEBYSKIN_LIST_SUCCESS = "GET_RACEBYSKIN_LIST_SUCCESS";
export const GET_RACEBYSKIN_LIST_ERROR = "GET_RACEBYSKIN_LIST_ERROR";

export const SET_ROUTE_LOCATIONS = "SET_ROUTE_LOCATIONS";

export const GET_TEAM_LIST_REQUEST = "GET_TEAM_LIST_REQUEST";
export const GET_TEAM_LIST_SUCCESS = "GET_TEAM_LIST_SUCCESS";
export const GET_TEAM_LIST_ERROR = "GET_TEAM_LIST_ERROR";

export const GET_QUESTION_LIST_REQUEST = "GET_QUESTION_LIST_REQUEST";
export const GET_QUESTION_LIST_SUCCESS = "GET_QUESTION_LIST_SUCCESS";
export const GET_QUESTION_LIST_ERROR = "GET_QUESTION_LIST_ERROR";

export const GET_PROGRESS_REPORT_REQUEST = "GET_PROGRESS_REPORT_REQUEST";
export const GET_PROGRESS_REPORT_SUCCESS = "GET_PROGRESS_REPORT_SUCCESS";
export const GET_PROGRESS_REPORT_ERROR = "GET_PROGRESS_REPORT_ERROR";

export const GET_REVIEW_LIST_REQUEST = "GET_REVIEW_LIST_REQUEST";
export const GET_REVIEW_LIST_SUCCESS = "GET_REVIEW_LIST_SUCCESS";
export const GET_REVIEW_LIST_ERROR = "GET_REVIEW_LIST_ERROR";

export const GET_TEAM_STATUS_REQUEST = "GET_TEAM_STATUS_REQUEST";
export const GET_TEAM_STATUS_SUCCESS = "GET_TEAM_STATUS_SUCCESS";
export const GET_TEAM_STATUS_ERROR = "GET_TEAM_STATUS_ERROR";
