import React, { Component } from "react";
import { Accordion, Card, Spinner } from "react-bootstrap";
import { showToast } from "../../common/ToastMsg";
import { locationCreate } from "./../../actions/location";
import JoditEditor from "jodit-react";
import { connect } from "react-redux";
import { getLocationList } from "./../../../redux/action/location";
import { getRouteList } from "./../../../redux/action/route";

class AddLocationAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "1",
      file: null,
      uploadFile: null,
      errors: {},
      saveLoading: false,
      race: "",
      location: "",
      address: "",
      mapLink: "",
      breifing: "",
      checkInCode: "",
      checkOutCode: "",
      maxDuration: "",
      minDuration: "",
      checkInDesc: "",
      startLocation: false,
      endLocation: false,
      taskDesc: "",
      cloneLoading: false,
      uploadType: "",
      uploadBreafType: "",
      breifingFile: [],
      uploadBreifingFile: [],
      locationMediaType: "",
      taskMediaType: "",
      videoUrl: "",
      taskVideoUrl: "",
    };
    this.uploadSingleFile = this.uploadSingleFile.bind(this);
  }

  // componentDidMount() {
  // this.getAllLocation();
  // this.getAllRace();
  // }

  handleChangeLocationMedia = (e) => {
    this.setState({
      locationMediaType: e.target.value,
      breifingFile: [],
    });
  };

  handleChangeTaskMedia = (e) => {
    this.setState({
      taskMediaType: e.target.value,
      file: [],
    });
  };
  handleLocation = (e) => {
    this.setState({
      location: e.target.value,
    });
  };

  handleAddress = (e) => {
    this.setState({
      address: e.target.value,
    });
  };

  handleMapLink = (e) => {
    this.setState({
      mapLink: e.target.value,
    });
  };

  handleBreifing = (newContent) => {
    this.setState({
      breifing: newContent,
    });
  };

  handleCheckInCode = (e) => {
    this.setState({
      checkInCode: e.target.value,
    });
  };

  handleCheckOutCode = (e) => {
    this.setState({
      checkOutCode: e.target.value,
    });
  };

  handleMaxDuration = (e) => {
    this.setState({
      maxDuration: e.target.value,
    });
  };

  handleMinDuration = (e) => {
    console.log("e.target.value: ", e.target.value);
    this.setState({
      minDuration: e.target.value,
    });
  };

  handleCodeLocation = (newContent) => {
    this.setState({
      checkInDesc: newContent,
    });
  };
  handleStartLocation = () => {
    this.setState({
      startLocation: !this.state.startLocation,
    });
  };

  handleEndLocation = () => {
    this.setState({
      endLocation: !this.state.endLocation,
    });
  };

  handleTaskDesc = (newContent) => {
    this.setState({
      taskDesc: newContent,
    });
  };

  handleMaxDuration = (e) => {
    console.log("e.target.value: ", e.target.value);
    this.setState({
      maxDuration: e.target.value,
    });
  };

  uploadSingleFile = (e) => {
    this.setState({
      file: URL.createObjectURL(e.target.files[0]),
      uploadFile: e.target.files[0],
    });

    setTimeout(() => {
      const uplodMedia = this.state.uploadFile.type.split("/");
      this.setState({
        uploadType: uplodMedia[0],
      });
    }, 500);
  };

  removeLocationMedia = () => {
    this.setState({
      file: null,
    });
  };

  handleVideoUrl = (e) => {
    this.setState({
      videoUrl: e.target.value,
    });
  };

  handleTaskVideoUrl = (e) => {
    this.setState({
      taskVideoUrl: e.target.value,
    });
  };

  uploadBreafingFile = (e) => {
    this.setState({
      breifingFile: URL.createObjectURL(e.target.files[0]),
      uploadBreifingFile: e.target.files[0],
    });

    setTimeout(() => {
      const uplodBreafMedia = this.state.uploadBreifingFile.type.split("/");
      this.setState({
        uploadBreafType: uplodBreafMedia[0],
      });
    }, 500);
  };

  removeBreafingMedia = () => {
    this.setState({
      breifingFile: [],
    });
  };

  fieldValication = () => {
    let isValid = true;
    const errors = {};
    if (this.state.location === "") {
      errors.locationnm = "location Required!!!";
      isValid = false;
    }
    if (this.state.checkInCode === "") {
      errors.checkin = "CheckIn code Required!!!";
      isValid = false;
    }
    if (this.state.checkOutCode === "") {
      errors.checkout = "CheckOut code Required!!!";
      isValid = false;
    }
    if (this.state.maxDuration === "") {
      errors.maxduration = "Max duration Required!!!";
      isValid = false;
    }
    if (this.state.minDuration === "") {
      errors.minDuration = "Min duration Required!!!";
      isValid = false;
    }
    if (parseInt(this.state.minDuration) >= parseInt(this.state.maxDuration)) {
      errors.limitduration =
        "Minmum duration should not greater than maximum duration!!!";
      isValid = false;
    }

    this.setState({
      errors,
    });
    return isValid;
  };

  handleCreateLocationSubmit = async () => {
    if (this.fieldValication()) {
      const formData = new FormData();
      formData.append("race", this.props.raceData?.item?._id);
      formData.append("name", this.state.location);
      formData.append("address", this.state.address);
      formData.append("mapLink", this.state.mapLink);
      formData.append("checkOut", this.state.checkOutCode);
      formData.append("checkIn", this.state.checkInCode);
      formData.append("breifingText", this.state.breifing);
      formData.append("duration", this.state.maxDuration);
      formData.append("minDuration", this.state.minDuration);
      formData.append("startLocation", this.state.startLocation);
      formData.append("finalLocation", this.state.endLocation);
      formData.append("description", this.state.taskDesc);
      formData.append("media", this.state.uploadFile);
      formData.append("breifing", this.state.uploadBreifingFile);
      formData.append("breifingUrl", this.state.videoUrl);
      formData.append("mediaUrl", this.state.taskVideoUrl);
      formData.append("checkInDescription", this.state.checkInDesc);

      this.setState({
        saveLoading: true,
      });

      const res = await locationCreate(formData);
      if (res) {
        this.setState({
          saveLoading: false,
        });
        showToast("success", "location Created successfully");
        this.props.GetLocationList(this.props.raceData?.item?._id);
        this.props.GetRouteList(this.props.raceData?.item?._id);
      } else {
        this.setState({
          saveLoading: false,
        });
      }
    }
  };

  render() {
    return (
      <>
        <Accordion
          defaultActiveKey="0"
          className="has-gap ms-accordion-chevron"
        >
          <Card>
            <Accordion.Collapse className="collapseparent" eventKey="0">
              <Card.Body>
                <form>
                  {Object.keys(this.state.errors).map((key) => {
                    return (
                      <div key={key} className="error-box">
                        {this.state.errors[key]}
                      </div>
                    );
                  })}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          htmlFor="location"
                          className="col-sm-3 col-form-label col-form-label-sm "
                        >
                          location Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            id="location"
                            placeholder="location name"
                            value={this.state.location}
                            onChange={this.handleLocation}
                          />
                        </div>
                      </div>

                      {/* <div className="form-group row">
                        <label
                          htmlFor="address"
                          className="col-sm-3 col-form-label col-form-label-sm "
                        >
                          Address
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            placeholder="address name"
                            value={this.state.address}
                            onChange={this.handleAddress}
                          />
                        </div>
                      </div> */}

                      <div className="form-group row">
                        <label
                          htmlFor="mapLink"
                          className="col-sm-3 col-form-label col-form-label-sm "
                        >
                          Google maps Link
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            id="mapLink"
                            placeholder="mapLink name"
                            value={this.state.mapLink}
                            onChange={this.handleMapLink}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="breifing"
                          className="col-sm-3 col-form-label col-form-label-sm "
                        >
                          Location Briefing instruction
                        </label>
                        <div className="col-sm-9">
                          <JoditEditor
                            value={this.state.breifing}
                            tabIndex={1}
                            onChange={(newContent) =>
                              this.handleBreifing(newContent)
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label col-form-label-sm">
                          Location Media type
                        </label>
                        <div className="col-sm-8">
                          <ul
                            className="ms-list d-flex"
                            onChange={this.handleChangeLocationMedia}
                          >
                            <li className="ms-list-item pl-0 pt-0">
                              <label className="ms-checkbox-wrap">
                                <input
                                  type="radio"
                                  name="radioExample1"
                                  value="none"
                                  defaultChecked
                                />
                                <i className="ms-checkbox-check" />
                              </label>
                              <span> None </span>
                            </li>
                            <li className="ms-list-item pt-0">
                              <label className="ms-checkbox-wrap">
                                <input
                                  type="radio"
                                  name="radioExample1"
                                  value="image"
                                />
                                <i className="ms-checkbox-check" />
                              </label>
                              <span> Image </span>
                            </li>
                            <li className="ms-list-item pt-0">
                              <label className="ms-checkbox-wrap">
                                <input
                                  type="radio"
                                  name="radioExample1"
                                  value="audio"
                                />
                                <i className="ms-checkbox-check" />
                              </label>
                              <span> Audio </span>
                            </li>
                            <li className="ms-list-item pt-0">
                              <label className="ms-checkbox-wrap">
                                <input
                                  type="radio"
                                  name="radioExample1"
                                  value="video"
                                />
                                <i className="ms-checkbox-check" />
                              </label>
                              <span> Video </span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {this.state.locationMediaType === "image" ? (
                        <div className="form-group row">
                          <label
                            htmlFor="file"
                            className="col-sm-3 col-form-label col-form-label-sm "
                          >
                            Location Briefing Media
                          </label>
                          <div className="col-sm-9">
                            <div className="custom-file form-group">
                              <input
                                type="file"
                                className="form-control custom-file-input"
                                onChange={this.uploadBreafingFile}
                                onClick={(event) => {
                                  event.target.value = null;
                                }}
                              />

                              <label
                                className="custom-file-label"
                                htmlFor="validatedCustomFile"
                              ></label>
                            </div>
                            {this.state.breifingFile &&
                            this.state.breifingFile.length > 0 ? (
                              <div className="form-group edit-preview">
                                <i
                                  className="fas fa-times-circle"
                                  onClick={this.removeBreafingMedia}
                                ></i>
                                <img alt="" src={this.state.breifingFile} />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}

                      {this.state.locationMediaType === "video" ? (
                        <div className="form-group row">
                          <label
                            htmlFor="file"
                            className="col-sm-3 col-form-label col-form-label-sm "
                          >
                            Location Briefing Media
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter url"
                              value={this.state.videoUrl}
                              onChange={this.handleVideoUrl}
                            />
                          </div>
                        </div>
                      ) : null}

                      {this.state.locationMediaType === "audio" ? (
                        <div className="form-group row">
                          <label
                            htmlFor="file"
                            className="col-sm-3 col-form-label col-form-label-sm "
                          >
                            Location Briefing Media
                          </label>
                          <div className="col-sm-9">
                            <div className="custom-file form-group">
                              <input
                                type="file"
                                className="form-control custom-file-input"
                                onChange={this.uploadBreafingFile}
                                onClick={(event) => {
                                  event.target.value = null;
                                }}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="validatedCustomFile"
                              ></label>
                            </div>
                            {this.state.breifingFile &&
                            this.state.breifingFile.length > 0 ? (
                              <div className="form-group preview">
                                <i
                                  className="fas fa-times-circle"
                                  onClick={this.removeBreafingMedia}
                                ></i>
                                <audio controls>
                                  <source
                                    src={this.state.breifingFile}
                                    type="audio/mpeg"
                                  />
                                </audio>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}

                      {/* <div className="form-group row">
                        <label
                          htmlFor="file"
                          className="col-sm-3 col-form-label col-form-label-sm "
                        >
                          Location Briefing Media
                        </label>
                        <div className="col-sm-9">
                          <div className="custom-file form-group">
                            <input
                              type="file"
                              className="form-control custom-file-input"
                              onChange={this.uploadBreafingFile}
                              onClick={(event) => {
                                event.target.value = null;
                              }}
                            />

                            <label
                              className="custom-file-label"
                              htmlFor="validatedCustomFile"
                            ></label>
                          </div>

                          {this.state.breifingFile &&
                          this.state.breifingFile.length > 0 ? (
                            <>
                              {this.state.uploadBreafType === "image" ? (
                                <div className="form-group edit-preview">
                                  <i
                                    className="fas fa-times-circle"
                                    onClick={this.removeBreafingMedia}
                                  ></i>
                                  <img alt="" src={this.state.breifingFile} />
                                </div>
                              ) : this.state.uploadBreafType === "audio" ? (
                                <div className="form-group preview">
                                  <i
                                    className="fas fa-times-circle"
                                    onClick={this.removeBreafingMedia}
                                  ></i>

                                  <audio controls>
                                    <source
                                      src={this.state.breifingFile}
                                      type="audio/mpeg"
                                    />
                                  </audio>
                                </div>
                              ) : this.state.uploadBreafType === "video" ? (
                                <div className="form-group preview">
                                  <i
                                    class="fas fa-times-circle"
                                    onClick={this.removeBreafingMedia}
                                  ></i>
                                  <video width="320" height="240">
                                    <source
                                      src={this.state.breifingFile}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                              ) : null}
                            </>
                          ) : this.props.data?.breifing?.mediaType ===
                            "image" ? (
                            <div className="form-group edit-preview">
                              <img
                                alt=""
                                src={
                                  IMAGE_URL +
                                  "" +
                                  this.props.data.breifing.media
                                }
                              />
                            </div>
                          ) : this.props.data?.breifing?.mediaType ===
                            "audio" ? (
                            <audio controls>
                              <source
                                src={
                                  IMAGE_URL +
                                  "" +
                                  this.props.data.breifing.media
                                }
                                type="audio/mpeg"
                              />
                            </audio>
                          ) : this.props.data?.breifing?.mediaType ===
                            "video" ? (
                            <video width="320" height="240">
                              <source
                                src={
                                  IMAGE_URL +
                                  "" +
                                  this.props.data.breifing.media
                                }
                                type="video/mp4"
                              />
                            </video>
                          ) : null}
                        </div>
                      </div> */}

                      <div className="form-group row">
                        <label
                          htmlFor="checkincode"
                          className="col-sm-3 col-form-label col-form-label-sm "
                        >
                          Check In Code
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            id="checkincode"
                            placeholder="checkincode"
                            value={this.state.checkInCode}
                            onChange={this.handleCheckInCode}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="checkoutcode"
                          className="col-sm-3 col-form-label col-form-label-sm "
                        >
                          Check Out Code
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            id="checkoutcode"
                            placeholder="checkoutcode"
                            value={this.state.checkOutCode}
                            onChange={this.handleCheckOutCode}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="maxduration"
                          className="col-sm-3 col-form-label col-form-label-sm "
                        >
                          Max Duration (Secs)
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            id="maxduration"
                            placeholder="maxduration"
                            value={this.state.maxDuration}
                            onChange={this.handleMaxDuration}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="minduration"
                          className="col-sm-3 col-form-label col-form-label-sm "
                        >
                          Min Duration (Secs)
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            id="minduration"
                            placeholder="minduration"
                            value={this.state.minDuration}
                            onChange={this.handleMinDuration}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="codelocation"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          Address
                        </label>
                        <div className="col-sm-7">
                          <JoditEditor
                            value={this.state.checkInDesc}
                            config={this.checkconfig}
                            tabIndex={1}
                            onChange={(newContent) =>
                              this.handleCodeLocation(newContent)
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-md-6">
                          <label className="ms-checkbox-wrap ms-checkbox-primary">
                            <input
                              type="checkbox"
                              name="startlocation"
                              disabled={
                                this.props.isStartLocationAvaible
                                  ? true
                                  : this.state.endLocation
                                  ? true
                                  : false
                              }
                              checked={this.state.startLocation}
                              onChange={this.handleStartLocation}
                            />
                            <i className="ms-checkbox-check" />
                          </label>
                          <span>Start Location</span>
                        </div>
                        <div className="col-md-6">
                          <label className="ms-checkbox-wrap ms-checkbox-primary">
                            <input
                              type="checkbox"
                              name="endlocation"
                              disabled={
                                this.props.isEndLocationAvaible
                                  ? true
                                  : this.state.startLocation
                                  ? true
                                  : false
                              }
                              checked={this.state.endLocation}
                              onChange={this.handleEndLocation}
                            />
                            <i className="ms-checkbox-check" />
                          </label>
                          <span>End Location</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="taskdesc">Task Description</label>

                        <JoditEditor
                          value={this.state.taskDesc}
                          tabIndex={1}
                          onChange={(newContent) =>
                            this.handleTaskDesc(newContent)
                          }
                        />
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label col-form-label-sm">
                          Task Media type
                        </label>
                        <div className="col-sm-8">
                          <ul
                            className="ms-list d-flex"
                            onChange={this.handleChangeTaskMedia}
                          >
                            <li className="ms-list-item pl-0 pt-0">
                              <label className="ms-checkbox-wrap">
                                <input
                                  type="radio"
                                  name="radioExample"
                                  value="none"
                                  defaultChecked
                                />
                                <i className="ms-checkbox-check" />
                              </label>
                              <span> None </span>
                            </li>
                            <li className="ms-list-item pt-0">
                              <label className="ms-checkbox-wrap">
                                <input
                                  type="radio"
                                  name="radioExample"
                                  value="image"
                                />
                                <i className="ms-checkbox-check" />
                              </label>
                              <span> Image </span>
                            </li>
                            <li className="ms-list-item pt-0">
                              <label className="ms-checkbox-wrap">
                                <input
                                  type="radio"
                                  name="radioExample"
                                  value="audio"
                                />
                                <i className="ms-checkbox-check" />
                              </label>
                              <span> Audio </span>
                            </li>
                            <li className="ms-list-item pt-0">
                              <label className="ms-checkbox-wrap">
                                <input
                                  type="radio"
                                  name="radioExample"
                                  value="video"
                                />
                                <i className="ms-checkbox-check" />
                              </label>
                              <span> Video </span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {this.state.taskMediaType === "image" ? (
                        <div className="form-group row">
                          <label
                            htmlFor="file"
                            className="col-sm-3 col-form-label col-form-label-sm"
                          >
                            Location Media
                          </label>
                          <div className="custom-file form-group col-sm-8">
                            <input
                              type="file"
                              className="form-control custom-file-input"
                              onChange={this.uploadSingleFile}
                              onClick={(event) => {
                                event.target.value = null;
                              }}
                            />

                            <label
                              className="custom-file-label"
                              htmlFor="validatedCustomFile"
                            ></label>
                          </div>
                          {this.state.file && this.state.file.length > 0 ? (
                            <div className="form-group edit-preview">
                              <i
                                className="fas fa-times-circle"
                                onClick={this.removeLocationMedia}
                              ></i>
                              <img alt="img" src={this.state.file} />
                            </div>
                          ) : null}
                        </div>
                      ) : this.state.taskMediaType === "audio" ? (
                        <div className="form-group row">
                          <label
                            htmlFor="file"
                            className="col-sm-3 col-form-label col-form-label-sm"
                          >
                            Location Media
                          </label>
                          <div className="custom-file form-group col-sm-8">
                            <input
                              type="file"
                              className="form-control custom-file-input"
                              onChange={this.uploadSingleFile}
                              onClick={(event) => {
                                event.target.value = null;
                              }}
                            />

                            <label
                              className="custom-file-label"
                              htmlFor="validatedCustomFile"
                            ></label>
                          </div>
                          {this.state.file && this.state.file.length > 0 ? (
                            <div className="form-group preview">
                              <i
                                className="fas fa-times-circle"
                                onClick={this.removeLocationMedia}
                              ></i>
                              <audio controls>
                                <source
                                  src={this.state.file}
                                  type="audio/mpeg"
                                />
                              </audio>
                            </div>
                          ) : null}
                        </div>
                      ) : this.state.taskMediaType === "video" ? (
                        <div className="form-group row">
                          <label
                            htmlFor="file"
                            className="col-sm-3 col-form-label col-form-label-sm"
                          >
                            Location Media
                          </label>
                          <div className="custom-file form-group col-sm-8">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter url"
                              value={this.state.taskVideoUrl}
                              onChange={this.handleTaskVideoUrl}
                            />
                          </div>
                        </div>
                      ) : null}

                      {/* <div className="form-group row">
                        <label
                          htmlFor="file"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          Location Media
                        </label>
                        <div className="custom-file form-group col-sm-8">
                          <input
                            type="file"
                            className="form-control custom-file-input"
                            onChange={this.uploadSingleFile}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                          />

                          <label
                            className="custom-file-label"
                            htmlFor="validatedCustomFile"
                          ></label>
                        </div>

                        {this.state.file ? (
                          <>
                            {this.state.uploadType === "image" ? (
                              <div className="form-group edit-preview">
                                <i
                                  className="fas fa-times-circle"
                                  onClick={this.removeLocationMedia}
                                ></i>
                                <img alt="" src={this.state.file} />
                              </div>
                            ) : this.state.uploadType === "audio" ? (
                              <div className="form-group preview">
                                <i
                                  className="fas fa-times-circle"
                                  onClick={this.removeLocationMedia}
                                ></i>

                                <audio controls>
                                  <source
                                    src={this.state.file}
                                    type="audio/mpeg"
                                  />
                                </audio>
                              </div>
                            ) : this.state.uploadType === "video" ? (
                              <div className="form-group preview">
                                <i
                                  class="fas fa-times-circle"
                                  onClick={this.removeLocationMedia}
                                ></i>
                                <video width="320" height="240">
                                  <source
                                    src={this.state.file}
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            ) : null}
                          </>
                        ) : this.props.data?.mediaType === "image" ? (
                          <div className="form-group edit-preview">
                            <img
                              alt=""
                              src={IMAGE_URL + "" + this.props.data.media}
                            />
                          </div>
                        ) : this.props.data?.mediaType === "audio" ? (
                          <audio controls>
                            <source
                              src={IMAGE_URL + "" + this.props.data.media}
                              type="audio/mpeg"
                            />
                          </audio>
                        ) : this.props.data?.mediaType === "video" ? (
                          <video width="320" height="240">
                            <source
                              src={IMAGE_URL + "" + this.props.data.media}
                              type="video/mp4"
                            />
                          </video>
                        ) : null}
                      </div> */}

                      <div className="form-group text-left">
                        {/* <Button
                          onClick={this.props.handleDeleteLocation}
                          // type="submit"
                          className=" btn btn-pill btn-primary mr-1 redbutton"
                        >
                          Delete Location
                          {this.state.loading && (
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                              className="ml-2"
                            />
                          )}
                        </Button> */}
                        {/* <Button
                          // type="submit"
                          onClick={this.props.handleCloneLocation}
                          className="btn btn-pill btn-primary mr-1 redbutton"
                        >
                          Clone Location
                          {this.state.cloneLoading && (
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                              className="ml-2"
                            />
                          )}
                        </Button> */}
                        <button
                          type="button"
                          onClick={this.handleCreateLocationSubmit}
                          className="btn btn-pill btn-primary  redbutton"
                        >
                          Save Location
                          {this.state.saveLoading && (
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                              className="ml-2"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Card.Body>
            </Accordion.Collapse>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span>
                {this.state.location ? this.state.location : "Add Location"}
              </span>
            </Accordion.Toggle>
          </Card>
        </Accordion>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.LocationList.locationList,
    fetching: state.LocationList.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetLocationList: (id) => dispatch(getLocationList(id)),
    GetRouteList: (id) => dispatch(getRouteList(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddLocationAccordion);
