import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
import { connect } from "react-redux";
import EditLocation from "./EditLocation";

class LocationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationData: [],
      isStartLocationAvailble: false,
      isEndLocationAvailable: false,
      locationName: "",
    };
  }

  render() {
    return (
      <>
        <Accordion className="has-gap ms-accordion-chevron">
          {this.props.fetching ? (
            <div className="spinner spinner-5" />
          ) : this.props.locations.length > 0 ? (
            this.props.locations.map((item, index) => {
              return (
                <>
                  <Card key={index.toString()}>
                    <Accordion.Collapse
                      className="collapseparent"
                      eventKey={index.toString()}
                    >
                      <Card.Body>
                        <EditLocation data={item} raceData={this.props.data} />
                      </Card.Body>
                    </Accordion.Collapse>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={index.toString()}
                    >
                      <span>{item.name}</span>
                    </Accordion.Toggle>
                  </Card>
                </>
              );
            })
          ) : (
            "No Data Found"
          )}
        </Accordion>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.LocationList.locationList,
    fetching: state.LocationList.fetching,
  };
};

export default connect(mapStateToProps, null)(LocationList);
