import { showToast } from "../common/ToastMsg";
import { apiInstance } from "../../httpClient/index";

export const createOptions = async (optionData) => {
  try {
    const res = await apiInstance.post(`option`, optionData);

    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const getRegiFieldList = async () => {
  try {
    const res = await apiInstance.get(`option`);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const createRace = async (formData) => {
  try {
    const res = await apiInstance.post(`race/create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const getAllRaceList = async () => {
  try {
    const res = await apiInstance.get(`race/get-all`);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const getSingleRace = async (id) => {
  try {
    const res = await apiInstance.get(`race/get-one/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const updateRace = async (id, formData) => {
  try {
    const res = await apiInstance.put(`race/update/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const deleteRace = async (id) => {
  try {
    const res = await apiInstance.delete(`race/remove/${id}`);

    if (res.status === 200) {
      return res;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};
