/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import { connect } from "react-redux";
import RouterEditForm from "./RouterEditForm";

class RouteList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        {this.props.fetching ? (
          <div className="spinner spinner-5" />
        ) : this.props.routes.length > 0 ? (
          this.props.routes.map((ele, i) => {
            return (
              <>
                <RouterEditForm
                  allData={ele}
                  raceData={this.props.data}
                  key={i.toString()}
                  locationsList={this.props.locationsList}
                />
              </>
            );
          })
        ) : (
          "No Data Found"
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.RouteList.routeList,
    fetching: state.RouteList.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, null)(RouteList);
