import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { deleteRace } from "../../actions/race";

export default class DeleteRace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
    };
  }

  handleModal = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleDeleteRace = async () => {
    this.setState({
      loading: true,
    });

    const res = await deleteRace(this.props.data._id);
    if (res) {
      this.setState({
        loading: false,
      });
      this.handleClose();
      window.location.href = "/race-list";

      // this.props.getAllRace();
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  render() {
    return (
      <>
        <i
          className="fas fa-trash fa-md"
          style={{ color: "#878793" }}
          onClick={this.handleModal}
        ></i>

        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3>Are you Sure you want to delete?</h3>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-light btn-pill "
              onClick={this.handleClose}
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-primary btn-pill redbutton"
              onClick={this.handleDeleteRace}
            >
              Yes
              {this.state.loading && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="ml-2"
                />
              )}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
