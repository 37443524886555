import { apiInstance } from "../../httpClient";
import * as actions from "./index";

export const getRouteList = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.getRouteListRequest());
    apiInstance
      .get(`route/race/${id}`)
      .then((res) => {
        dispatch(actions.getRouteListSuccess(res.data.data));
        resolve();
      })
      .catch((e) => {
        dispatch(actions.getRouteListError(e?.response?.data?.message));
        reject();
      });
  });

export const SetRouteLocations = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.setRouteLocations(data));
    resolve();
  });
