import React, { Component } from "react";
import Breadcrumb from "./Breadcrumb";
import "react-datetime/css/react-datetime.css";
import { withRouter } from "react-router-dom";
import AddRaceForm from "./AddRaceForm";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      races: [],
    };
  }
  componentDidMount() {}

  handleOption = (data) => {
    if (this.state.options.includes(data.id)) {
      const indx = this.state.options.indexOf(data.id);
      this.state.options.splice(indx, 1);
    } else {
      this.state.options.push(data.id);
    }
  };

  render() {
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />

            <AddRaceForm />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Content);
