import React, { Component } from "react";
import Breadcrumb from "./Breadcrumb";
import "react-datetime/css/react-datetime.css";
import { Button, Nav, Tab, Modal } from "react-bootstrap";
import AddLocationAccordion from "./AddLocationAccordion";
import RouteList from "./RouteList";
import AddRoute from "./AddRoute";
import GenerateTeams from "./GenerateTeams";
import Results from "./Results";
import Progress from "./Progress";
import LocationList from "./LocationList";
import { withRouter } from "react-router-dom";
import { AddRaceForm } from "../Add-race/AddRaceForm";
import { getAllRaceList } from "../../actions/race";
import { getQuestionsList } from "./../../actions/questions";
import DeleteTeam from "./DeleteTeam";
import moment from "moment";
import { getResultData, getReviewsList } from "../../actions/result";
import ShareExportFile from "./ShareExportFile";
import { getLocationList } from "./../../../redux/action/location";
import { connect } from "react-redux";
import { getRouteList } from "./../../../redux/action/route";
import AddQuestion from "./AddQuestion";
import QuestionList from "./QuestionList";
import TeamReview from "./TeamReview";
import TeamReviewQuestions from "./TeamReviewQuestions";
import BulkTeams from "./BulkTeams";
import { getTeamList } from "./../../../redux/action/team";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "1",
      locationNumber: 0,
      location1: "",
      show1: false,
      showTeam: false,
      showTeamDelete: false,
      showQue: false,
      teamList: [],
      routeList: [],
      resultData: [],
      questionData: [],
      teamData: {},
      isEdit: "",
      showBulkTeam: false,
      routeLoading: false,

      teamLoading: false,
      resultLoading: false,
    };
    this.handleModal = this.handleModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  componentDidMount() {
    if (this.props.history?.location?.state?.item) {
      this.getAllRace();
      this.getAllQuestions();
      this.props.GetLocationList(this.props.history.location.state?.item?._id);
      this.props.GetRouteList(this.props.history.location.state?.item?._id);
      this.props.GetTeamList(this.props.history.location.state?.item?._id);
      this.getAllResult();
    } else {
      window.location.href = "/race-list";
    }
  }

  getAllRace = async () => {
    this.setState({
      loading: true,
    });
    const res = await getAllRaceList();
    if (res) {
      this.setState({
        raceList: res,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  getAllRoute = async () => {
    this.setState({
      routeLoading: true,
    });
    const res = await getRouteList(
      this.props.history.location.state?.item?._id
    );
    if (res) {
      this.setState({
        routeList: res,
        routeLoading: false,
      });
    } else {
      this.setState({
        routeLoading: false,
      });
    }
  };

  // getAllTeam = async () => {
  //   this.setState({
  //     teamLoading: true,
  //   });
  //   const res = await getTeamList(this.props.history.location.state?.item?._id);
  //   if (res) {
  //     this.setState({
  //       teamList: res,
  //       teamLoading: false,
  //     });
  //   } else {
  //     this.setState({
  //       teamLoading: false,
  //     });
  //   }
  // };

  getAllResult = async () => {
    this.setState({
      resultLoading: true,
    });
    const res = await getResultData(
      this.props.history.location.state?.item?._id
    );
    if (res) {
      this.setState({
        resultData: res,
        resultLoading: false,
      });
    } else {
      this.setState({
        resultLoading: false,
      });
    }
  };
  getAllQuestions = async () => {
    this.setState({
      resultLoading: true,
    });
    const res = await getQuestionsList(
      this.props.history.location.state?.item?._id
    );
    if (res) {
      this.setState({
        questionData: res,
        resultLoading: false,
      });
    } else {
      this.setState({
        resultLoading: false,
      });
    }
  };

  getReviews = async () => {
    let res = await getReviewsList(this.props.data?.item?.id);
    if (res) {
      this.setState({
        reviews: res,
      });
    } else {
      // showToast("error", "Somthing went wrong to get reviews!");
    }
  };

  handleModal() {
    this.setState({ show: true });
  }

  handleTeamModal = (item) => {
    this.setState({
      teamData: item,
      showTeam: true,
      isEdit: "Edit",
    });
  };

  handleTeamCreateModal = () => {
    this.setState({
      isEdit: "Add",
      showTeam: true,
    });
  };
  handleBulkTeamModal = () => {
    this.setState({
      showBulkTeam: true,
    });
  };
  handleBulkTeamClose = () => {
    this.setState({
      showBulkTeam: false,
    });
  };

  handleQustionModal = () => {
    this.setState({
      showQue: true,
    });
  };

  handleQustionModalClose = () => {
    this.setState({
      showQue: false,
    });
  };

  handleTeamClose = () => {
    this.setState({ showTeam: false });
  };

  handleTeamDeleteModal = (item) => {
    this.setState({ showTeamDelete: true });
    this.setState({
      teamData: item._id,
    });
  };

  handleTeamDeleteClose = () => {
    this.setState({ showTeamDelete: false });
  };

  handleShow() {
    this.setState({ show1: true });
  }

  handleClose() {
    this.setState({ show1: false });
  }

  handleTab = (k) => {
    this.setState({ key: k });
  };

  handleCloneLocation = () => {
    this.setState(
      {
        locationNumber: this.state.locationNumber + 1,
      },
      () => {
        let loc = {
          component: (
            <AddLocationAccordion
              name={`Location ${this.state.locationNumber}`}
              handleCloneLocation={this.handleCloneLocation}
              handleDeleteLocation={this.handleDeleteLocation}
              raceData={this.props.history.location.state}
              isStartLocationAvaible={this.props.locations.some((item) => {
                return item.startLocation ? true : false;
              })}
              isEndLocationAvaible={this.props.locations.some((item) => {
                return item.finalLocation ? true : false;
              })}
            />
          ),
        };
        let arr = this.props.locations;
        arr.push(loc);
        this.setState({
          locations: arr,
        });
      }
    );
  };

  handleDeleteLocation = () => {
    this.state.locations.filter((ele, index) => {
      return index;
    });
  };

  render() {
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb data={this.props.history.location.state} />
            <div className="ms-panel">
              <div className="ms-panel-body p-0">
                <Tab.Container
                  defaultActiveKey="1"
                  activeKey={this.state.key}
                  onSelect={this.handleTab}
                >
                  <Nav
                    variant="tabs"
                    className="nav nav-tabs d-flex tabs-round has-gap nav-justified mb-4"
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="1">Settings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="2">Locations</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="3">Routes</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="4">Question</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="5">Teams</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="6">Progess</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="7">Results</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="8">Q&A</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="1">
                      <AddRaceForm
                        history={this.props.history}
                        // data={this.props.history.location.state}
                        data={this.props.history.location.state}
                        getAllRace={this.getAllRace}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn btn-pill redbutton mb-3 mt-0"
                          onClick={this.handleCloneLocation}
                        >
                          Add Location
                        </Button>
                      </div>
                      <LocationList
                        data={this.props.history.location.state}
                        // getStartFlag={this.getStartFlag}
                        // getEndFlag={this.getEndFlag}
                        // locationsList={this.props.locations}
                        // locLoading={this.props.fetching}
                      />

                      {this.props.locations.map((item) => {
                        return item.component;
                      })}
                    </Tab.Pane>
                    <Tab.Pane eventKey="3">
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn btn-pill redbutton mb-3 mt-0"
                          onClick={this.handleShow}
                        >
                          + Add Route
                        </Button>

                        <Modal
                          size="md"
                          show={this.state.show1}
                          onHide={this.handleClose}
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                        >
                          {this.state.key === "3" ? (
                            <AddRoute
                              handleClose={this.handleClose}
                              data={this.props.history.location.state}
                              // getAllRoute={this.getAllRoute}
                            />
                          ) : null}
                        </Modal>
                      </div>
                      <RouteList
                        data={this.props.history.location.state}
                        // routeList={this.props.routeList}
                        // fetching={this.props.fetching}
                        // locationsList={this.props.locations}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="4">
                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={this.handleQustionModal}
                          className="btn btn-pill redbutton mb-3 mt-0"
                        >
                          Add Question
                        </Button>
                        <Modal
                          show={this.state.showQue}
                          size="md"
                          onHide={this.handleQustionModalClose}
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                        >
                          {this.state.key === "4" ? (
                            <AddQuestion
                              handleClose={this.handleQustionModalClose}
                              data={this.props.history.location.state}
                              getData={() => this.getAllQuestions()}
                            />
                          ) : null}
                        </Modal>
                      </div>
                      <QuestionList
                        data={this.props.history.location.state}
                        questionList={this.state.questionData}
                        fetching={this.props.fetching}
                        getData={() => this.getAllQuestions()}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="5">
                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={this.handleTeamCreateModal}
                          className="btn btn-pill redbutton mb-3 mt-0"
                        >
                          Generate Teams
                        </Button>
                        <Button
                          onClick={this.handleBulkTeamModal}
                          className="btn btn-pill redbutton mb-3 mt-0"
                        >
                          Bulk Teams
                        </Button>
                        <Modal
                          show={this.state.showTeam}
                          size="lg"
                          onHide={this.handleTeamClose}
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                        >
                          {this.state.key === "5" ? (
                            <GenerateTeams
                              handleClose={this.handleTeamClose}
                              data={this.props.history.location.state}
                              singleTeamData={this.state.teamData}
                              isEdit={this.state.isEdit}
                              // routeList={this.state.routeList}
                            />
                          ) : null}

                          {/* <AddTeams handleClose={this.handleClose} /> */}
                        </Modal>
                        <Modal
                          show={this.state.showBulkTeam}
                          size="md"
                          onHide={this.handleBulkTeamClose}
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                        >
                          {this.state.key === "5" ? (
                            <BulkTeams
                              handleClose={this.handleBulkTeamClose}
                              raceData={this.props.history.location.state}
                            />
                          ) : null}
                        </Modal>
                      </div>
                      {/* <TeamList data={this.props.history.location.state} /> */}

                      <div className="table-responsive">
                        {/* <table id="data-table-4" className="table w-100 thead-primary" /> */}
                        {this.props.teamFetching ? (
                          <div className="spinner spinner-5" />
                        ) : (
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>
                                  <label className="ms-checkbox-wrap">
                                    <input
                                      type="checkbox"
                                      name="checkAll"
                                      checked={this.state.allChecked}
                                      onClick={this.handleChange}
                                    />
                                    <i className="ms-checkbox-check" />
                                  </label>
                                </th>
                                <th scope="col">Team</th>
                                <th scope="col">Type</th>
                                <th scope="col">Route</th>
                                <th scope="col">Captain</th>
                                <th scope="col">Email Id</th>
                                <th scope="col">Created Date</th>
                                <th scope="col">Updated Date</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.teams.length > 0 ? (
                                this.props.teams.map((item, index) => {
                                  return (
                                    <tr key={item.id}>
                                      <th>
                                        <label className="ms-checkbox-wrap">
                                          <input
                                            key={item.id}
                                            type="checkbox"
                                            name={item.name}
                                            value={item.name}
                                            checked={item.isChecked}
                                            onClick={this.handleChange}
                                          />
                                          <i className="ms-checkbox-check" />
                                        </label>
                                      </th>
                                      <th scope="row">{item.name}</th>
                                      <td>
                                        {item.test === true ? "Test" : "Race"}
                                      </td>
                                      <td> {item.route?.name}</td>
                                      <td>
                                        {item.captain_name
                                          ? item.captain_name
                                          : item?.members[0]
                                          ? item.members[0]?.name
                                          : "-"}
                                      </td>
                                      <td> {item.email}</td>
                                      <td>
                                        {moment(item.createdAt).format(
                                          "DD/MM/YYYY HH:mm"
                                        )}
                                      </td>
                                      <td>
                                        {moment(item.updatedAt).format(
                                          "DD/MM/YYYY HH:mm"
                                        )}
                                      </td>

                                      <td>
                                        {/* <EditTeams
                                            dataaa={item}
                                            raceData={this.props.data}
                                          /> */}

                                        <i
                                          onClick={() =>
                                            this.handleTeamModal(item)
                                          }
                                          className="fas fa-edit fa-md"
                                          style={{ color: "#666674" }}
                                        ></i>
                                        <i
                                          onClick={() =>
                                            this.handleTeamDeleteModal(item)
                                          }
                                          className="fas fa-trash fa-md"
                                          style={{ color: "#666674" }}
                                        ></i>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="8" className="text-center">
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>

                      <Modal
                        show={this.state.showTeamDelete}
                        size="md"
                        onHide={this.handleTeamDeleteClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        {this.state.key === "5" ? (
                          <DeleteTeam
                            handleTeamDeleteClose={this.handleTeamDeleteClose}
                            singleTeamDataDelete={this.state.teamData}
                            data={this.props.history.location.state}
                            // getAllTeam={this.getAllTeam}
                          />
                        ) : null}
                      </Modal>
                    </Tab.Pane>
                    <Tab.Pane eventKey="6">
                      <Progress
                        data={this.props.history.location.state}
                        resultLoading={this.state.resultLoading}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="7">
                      <ShareExportFile
                        resultData={this.state.resultData}
                        data={this.props.history.location.state}
                      />
                      <Results
                        data={this.props.history.location.state}
                        resultLoading={this.state.resultLoading}
                        resultData={this.state.resultData}
                      />
                      <TeamReview data={this.props.history.location.state} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="8">
                      <TeamReviewQuestions
                        data={this.props.history.location.state}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>

              {/* <Button
                type="submit"
                className="redbutton create-race"
                onClick={this.handleCreateRace}
              >
                Create Race
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.LocationList.locationList,
    routes: state.RouteList.routeList,
    fetching: state.LocationList.fetching,
    teamFetching: state.TeamList.fetching,
    teams: state.TeamList.teamList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetLocationList: (id) => dispatch(getLocationList(id)),
    GetRouteList: (id) => dispatch(getRouteList(id)),
    GetTeamList: (id) => dispatch(getTeamList(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Content));
