import React, { Component } from "react";
import Breadcrumb from "./Breadcrumb";
import { Modal } from "react-bootstrap";
import DeleteGroup from "./DeleteGroup";
import AddGroup from "./AddGroup";
import { getGroupList } from "./../../actions/group";
import EditGroup from "./EditGroup";

export default class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      groupList: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.getAllGroup();
  }

  handleModal = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  getAllGroup = async () => {
    this.setState({
      loading: true,
    });
    const res = await getGroupList();
    if (res) {
      this.setState({
        groupList: res,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />

            <div className="ms-panel">
              <div className="ms-panel-header d-flex justify-content-between align-items-center">
                <h6>Group List</h6>
                <button
                  type="button"
                  className="btn btn-pill btn-primary m-0 redbutton"
                  onClick={this.handleModal}
                >
                  + Add Group
                </button>
              </div>
              <Modal
                show={this.state.show}
                onHide={this.handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <AddGroup
                  handleClose={this.handleClose}
                  getAllGroup={this.getAllGroup}
                />
              </Modal>
              <div className="ms-panel-body">
                <div className="table-responsive">
                  {/* <table id="data-table-4" className="table w-100 thead-primary" /> */}
                  {this.state.loading ? (
                    <div className="spinner spinner-5" />
                  ) : (
                    <table className="table table-striped ">
                      <thead>
                        <tr>
                          <th scope="col">Group Name</th>

                          <th scope="col" className="text-right">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.groupList.length > 0
                          ? this.state.groupList.map((item) => {
                              return (
                                <tr key={item.id.toString()}>
                                  <td>{item.name}</td>

                                  <td className="text-right">
                                    <EditGroup
                                      allData={item}
                                      getAllGroup={this.getAllGroup}
                                    />

                                    <DeleteGroup
                                      data={item}
                                      getAllGroup={this.getAllGroup}
                                    />
                                  </td>
                                </tr>
                              );
                            })
                          : "No Data Found"}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
