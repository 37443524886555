import { apiInstance } from "../../httpClient";
import * as actions from "./index";

export const getTeamList = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.getTeamListRequest());

    apiInstance
      .get(`team/race/${id}`)
      .then((res) => {
        dispatch(actions.getTeamListSuccess(res.data.data));
        resolve();
      })
      .catch((e) => {
        console.log("e: ", e.response);
        dispatch(actions.getTeamListError(e?.response?.data?.message));
        reject();
      });
  });

export const getTeamStatus = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.getTeamStatusRequest());

    apiInstance
      .get(`team/status/${id}`)
      .then((res) => {
        dispatch(actions.getTeamStatusSuccess(res.data.data));
        resolve();
      })
      .catch((e) => {
        console.log("e: ", e.response);
        dispatch(actions.getTeamStatusError(e?.response?.data?.message));
        reject();
      });
  });
