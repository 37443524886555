import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import AddGroup from "./AddGroup";

export default class EditGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
    };
  }

  handleModal = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };
  render() {
    return (
      <>
        <Link>
          <i
            className="fas fa-edit fa-md"
            style={{ color: "#878793" }}
            onClick={this.handleModal}
          ></i>
        </Link>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <AddGroup
            handleClose={this.handleClose}
            data={this.props.allData}
            allGroup={this.props.getAllGroup}
          />
        </Modal>
      </>
    );
  }
}
