import { showToast } from "../common/ToastMsg";
import { apiInstance } from "./../../httpClient/index";

export const getQuestionsList = async (id) => {
  try {
    const res = await apiInstance.get(`question/get-all?race=${id}`);
    if (res.status === 200) {
      return res.data.data;
    } else {
      return null;
    }
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const createQuestions = async (routeBody) => {
  try {
    const res = await apiInstance.post(`question/create`, routeBody);
    console.log("create que res: ", res);
    if (res.status === 200) {
      return res.data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const updateQuestions = async (id, routeBody) => {
  try {
    const res = await apiInstance.put(`question/update/${id}`, routeBody);
    if (res.status === 200) {
      return res.data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const questionDelete = async (id) => {
  try {
    const res = await apiInstance.delete(`question/remove/${id}`);
    if (res.status === 200) {
      return res.data.message;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};
