import { showToast } from "../common/ToastMsg";
import { apiInstance } from "./../../httpClient/index";
import { setToken } from "./../../httpClient/ClientHelper";
import { setUserSession } from "../../utils/common";

export const setLogin = async (loginData) => {
  try {
    const res = await apiInstance.post(`auth/login`, loginData);
    if (res.status === 200) {
      setToken(res.data.data.token);
      setUserSession(
        res.data.data.token,
        res.data.data.username,
        res.data.data.email,
        res.data.data
      );
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};
