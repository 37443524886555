import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { showToast } from "../../common/ToastMsg";
import { createBulkTeam } from "./../../actions/team";
import { connect } from "react-redux";
import { getTeamList } from "./../../../redux/action/team";

class BulkTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamNum: "",
      teamName: "",
      idType: false,
      loading: false,
    };
  }

  handleTeamNum = (e) => {
    this.setState({
      teamNum: e.target.value,
    });
  };

  handleidType = (e) => {
    this.setState({
      idType: !this.state.idType,
    });
  };

  handleTeamName = (e) => {
    this.setState({
      teamName: e.target.value,
    });
  };

  handleBulkCreateTeam = async () => {
    const teamData = {
      race: this.props.raceData?.item?._id,
      name: this.state.teamName.toLowerCase(),
      test: this.state.idType,
      noOfTeam: Number(this.state.teamNum),
    };
    this.setState({
      loading: true,
    });
    try {
      const res = await createBulkTeam(teamData);
      if (res) {
        this.setState({
          loading: false,
        });
        showToast("success", "Team created successfully");
        this.props.handleClose();
        this.props.GetTeamList(this.props.raceData?.item?._id);
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      console.log("e: ", e.response);
    }
  };
  render() {
    return (
      <>
        <Modal.Header>
          <h3 className="modal-title has-icon ms-icon-round ">
            Bulk Team Generate
          </h3>
          <button
            type="button"
            className="close"
            onClick={this.props.handleClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="teamName">Team No</label>
              <input
                type="text"
                className="form-control"
                id="teamNum"
                placeholder="Team No"
                value={this.state.teamNum}
                onChange={this.handleTeamNum}
              />
            </div>

            <div className="form-group">
              <label className="ms-checkbox-wrap ms-checkbox-primary">
                <input
                  type="checkbox"
                  name="idtype"
                  checked={this.state.idType}
                  onChange={this.handleidType}
                />
                <i className="ms-checkbox-check" />
              </label>
              <span>Test User</span>
            </div>

            <div className="form-group">
              <label htmlFor="teamName">Team name</label>
              <input
                type="text"
                className="form-control"
                id="teamName"
                placeholder="Team name"
                value={this.state.teamName}
                onChange={this.handleTeamName}
              />
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-light btn-pill mr-2"
              onClick={this.props.handleClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary redbutton btn-pill"
              onClick={this.handleBulkCreateTeam}
            >
              Add
              {this.state.loading && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="ml-2"
                />
              )}
            </button>
          </div>
        </Modal.Body>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    teamFetching: state.TeamList.fetching,
    teams: state.TeamList.teamList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetTeamList: (id) => dispatch(getTeamList(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkTeams);
