import React, { Component } from "react";
import { Button, Spinner, Modal } from "react-bootstrap";
import { IMAGE_URL } from "../../../utils/common";
import { updateLocation } from "../../actions/location";
import { getAllRaceList } from "../../actions/race";
import { showToast } from "../../common/ToastMsg";
import { deleteLocation } from "./../../actions/location";
import JoditEditor from "jodit-react";
import { getLocationList } from "../../../redux/action/location";
import { connect } from "react-redux";
import { getRouteList } from "./../../../redux/action/route";

class EditLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      editLoading: false,
      deleteLoading: false,
      race: this.props.data?.race || "",
      location: this.props.data?.name || "",
      // address: this.props.data?.address || "",
      mapLink: this.props.data?.mapLink || "",
      breifing: this.props.data?.breifingText || "",
      checkInCode: this.props.data?.checkIn || "",
      checkOutCode: this.props.data?.checkOut || "",
      maxDuration: this.props.data?.duration || "",
      minDuration: this.props.data?.minDuration || "",
      checkInDesc: this.props.data?.checkInDescription || "",
      startLocation: this.props.data?.startLocation || "",
      endLocation: this.props.data?.finalLocation || "",
      taskDesc: this.props.data?.description || "",
      isEdit: false,
      raceListOption: [],
      file: [],
      uploadFile: [],
      show: false,
      uploadBreafType: "",
      breifingFile: [],
      uploadBreifingFile: [],
      breifingMediaImg: this.props.data?.breifing?.media,
      locationMediaImg: this.props.data?.media,
      isBreifingNull: false,
      isMediaNull: false,
      locationMediaType: this.props.data?.breifing?.mediaType || " ",
      taskMediaType: this.props.data?.mediaType || "",
      locationVideoUrl:
        this.props.data?.breifing?.mediaType === "video"
          ? this.props.data?.breifing?.media
          : "",
      taskVideoUrl:
        this.props.data?.mediaType === "video" ? this.props.data?.media : "",
      // EditLocationMediaType: this.props.data?.breifing?.mediaType,
    };
  }
  componentDidMount() {
    this.getAllRace();
  }

  handleChangeLocationMedia = (e) => {
    this.setState({
      locationMediaType: e.target.value,
      breifingFile: [],
      uploadBreifingFile: [],
      locationVideoUrl: "",
    });
  };

  handleVideoUrl = (e) => {
    this.setState({
      locationVideoUrl: e.target.value,
    });
  };
  handleTaskVideoUrl = (e) => {
    this.setState({
      taskVideoUrl: e.target.value,
    });
  };
  handleChangeTaskMedia = (e) => {
    this.setState({
      taskMediaType: e.target.value,
      file: [],
      uploadFile: [],
      taskVideoUrl: "",
    });
  };

  getAllRace = async () => {
    this.setState({
      loading: true,
    });
    const res = await getAllRaceList();
    if (res) {
      this.setState({
        raceListOption: res,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handlerace = (e) => {
    this.setState({
      race: e.target.value,
    });
  };

  handleLocation = (e) => {
    this.setState({
      location: e.target.value,
    });
  };

  handleAddress = (e) => {
    this.setState({
      address: e.target.value,
    });
  };

  handleMapLink = (e) => {
    this.setState({
      mapLink: e.target.value,
    });
  };

  handleBreifing = (newContent) => {
    this.setState({
      breifing: newContent,
    });
  };

  handleCheckInCode = (e) => {
    this.setState({
      checkInCode: e.target.value,
    });
  };

  handleCheckOutCode = (e) => {
    this.setState({
      checkOutCode: e.target.value,
    });
  };

  handleMaxDuration = (e) => {
    this.setState({
      maxDuration: e.target.value,
    });
  };

  handleMinDuration = (e) => {
    this.setState({
      minDuration: e.target.value,
    });
  };

  handleCodeLocation = (newContent) => {
    this.setState({
      checkInDesc: newContent,
    });
  };

  handleStartLocation = () => {
    this.setState({
      startLocation: !this.state.startLocation,
    });
  };

  handleEndLocation = () => {
    this.setState({
      endLocation: !this.state.endLocation,
    });
  };

  handleTaskDesc = (newContent) => {
    this.setState({
      taskDesc: newContent,
    });
  };

  handleMaxDuration = (e) => {
    this.setState({
      maxDuration: e.target.value,
    });
  };

  uploadSingleFile = (e) => {
    this.setState({
      file: URL.createObjectURL(e.target.files[0]),
      uploadFile: e.target.files[0],
      isMediaNull: false,
    });

    setTimeout(() => {
      const uplodMedia = this.state.uploadFile.type.split("/");
      this.setState({
        uploadType: uplodMedia[0],
      });
    }, 500);
  };

  removeBreafingMedia = () => {
    this.setState({
      uploadBreifingFile: [],
      breifingFile: [],
      isBreifingNull: true,
    });
  };

  handleRemoveMediaProps = () => {
    alert("hiii");
    this.setState({
      breifingMediaImg: "",
      isBreifingNull: true,
    });
  };
  uploadBreafingFile = (e) => {
    this.setState({
      breifingFile: URL.createObjectURL(e.target.files[0]),
      uploadBreifingFile: e.target.files[0],
      isBreifingNull: false,
    });

    setTimeout(() => {
      const uplodBreafMedia = this.state.uploadBreifingFile.type.split("/");
      this.setState({
        uploadBreafType: uplodBreafMedia[0],
      });
    }, 500);
  };

  removeLocationMedia = () => {
    this.setState({
      file: [],
      uploadFile: [],
      isMediaNull: true,
    });
  };

  removeEditLocationMedia = () => {
    this.setState({
      locationMediaImg: "",
      isMediaNull: true,
    });
  };
  removeEditBreafingMedia = () => {
    this.setState({
      breifingMediaImg: "",
      isBreifingNull: true,
    });
  };
  handleEditLocation = () => {
    this.setState({
      isEdit: !this.state.isEdit,
    });
  };

  fieldValication = () => {
    let isValid = true;
    const errors = {};
    if (this.state.location === "") {
      errors.locationnm = "location Required!!!";
      isValid = false;
    }
    if (this.state.checkInCode === "") {
      errors.checkin = "CheckIn code Required!!!";
      isValid = false;
    }
    if (this.state.checkOutCode === "") {
      errors.checkout = "CheckOut code Required!!!";
      isValid = false;
    }
    if (this.state.maxDuration === "") {
      errors.maxduration = "Max duration Required!!!";
      isValid = false;
    }
    if (this.state.minDuration === "") {
      errors.minDuration = "Min duration Required!!!";
      isValid = false;
    }
    if (this.state.minDuration > this.state.maxDuration) {
      errors.limitduration =
        "Minmum duration should not greater than maximum duration!!!";
      isValid = false;
    }

    this.setState({
      errors,
    });
    return isValid;
  };
  handlUpdateLocation = async () => {
    if (this.fieldValication()) {
      const formData = new FormData();

      formData.append("race", this.props.raceData.item._id);
      formData.append("name", this.state.location);
      formData.append("address", this.state.address);
      formData.append("mapLink", this.state.mapLink);

      formData.append("checkOut", this.state.checkOutCode);
      formData.append("checkIn", this.state.checkInCode);
      formData.append("breifingText", this.state.breifing);
      formData.append("duration", this.state.maxDuration);
      formData.append("minDuration", this.state.minDuration);
      formData.append("startLocation", this.state.startLocation);
      formData.append("finalLocation", this.state.endLocation);
      formData.append("description", this.state.taskDesc);

      formData.append("media", this.state.uploadFile);
      // if (this.state.uploadFile && this.state.uploadFile.length > 0) {
      // }

      formData.append("breifing", this.state.uploadBreifingFile);
      if (
        this.state.locationVideoUrl &&
        this.state.locationVideoUrl.trim() !== ""
      ) {
        formData.append("breifingUrl", this.state.locationVideoUrl);
      }

      if (this.state.taskVideoUrl && this.state.taskVideoUrl.trim() !== "") {
        formData.append("mediaUrl", this.state.taskVideoUrl);
      }

      // formData.append("mediaUrl", this.state.taskVideoUrl);
      formData.append("checkInDescription", this.state.checkInDesc);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      this.setState({
        editLoading: true,
      });

      const res = await updateLocation(this.props.data._id, formData);
      console.log(" update res: ", res);

      if (res) {
        showToast("success", "location updated successfully");
        this.props.GetLocationList(this.props.raceData.item._id);
        this.setState({
          editLoading: false,
          isEdit: !this.state.isEdit,
        });
      } else {
        this.setState({
          editLoading: false,
        });
      }
    }
  };

  handleModal = () => {
    this.setState({
      show: true,
    });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  handleLocationDelete = async () => {
    this.setState({
      deleteLoading: true,
    });
    const res = await deleteLocation(this.props.data._id);
    if (res) {
      this.setState({
        deleteLoading: false,
        show: false,
      });
      this.props.GetLocationList(this.props.raceData.item._id);
      showToast("success", "Location deleted successfully");
      setTimeout(() => {
        this.props.GetRouteList(this.props.raceData?.item?._id);
      }, 1000);
    } else {
      this.setState({
        show: false,
      });
    }
  };
  render() {
    return (
      <>
        <form>
          {Object.keys(this.state.errors).map((key) => {
            return (
              <div key={key} className="error-box">
                {this.state.errors[key]}
              </div>
            );
          })}
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label
                  htmlFor="location"
                  className="col-sm-3 col-form-label col-form-label-sm "
                >
                  location Name
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    id="location"
                    disabled={!this.state.isEdit}
                    placeholder="location name"
                    value={this.state.location}
                    onChange={this.handleLocation}
                  />
                </div>
              </div>

              {/* <div className="form-group row">
                <label
                  htmlFor="address"
                  className="col-sm-3 col-form-label col-form-label-sm "
                >
                  Address
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    disabled={!this.state.isEdit}
                    placeholder="address name"
                    value={this.state.address}
                    onChange={this.handleAddress}
                  />
                </div>
              </div> */}

              <div className="form-group row">
                <label
                  htmlFor="mapLink"
                  className="col-sm-3 col-form-label col-form-label-sm "
                >
                  Google maps Link
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    disabled={!this.state.isEdit}
                    className="form-control"
                    id="mapLink"
                    placeholder="mapLink name"
                    value={this.state.mapLink}
                    onChange={this.handleMapLink}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="breifing"
                  className="col-sm-3 col-form-label col-form-label-sm "
                >
                  Location Briefing instruction
                </label>
                <div className="col-sm-9">
                  <JoditEditor
                    readOnly={false}
                    config={this.config}
                    // disabled={!this.state.isEdit}
                    value={this.state.breifing}
                    tabIndex={1}
                    onChange={(newContent) => this.handleBreifing(newContent)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label col-form-label-sm">
                  Location Media type
                </label>
                <div className="col-sm-8">
                  <ul
                    className="ms-list d-flex"
                    onChange={this.handleChangeLocationMedia}
                  >
                    <li className="ms-list-item pl-0 pt-0">
                      <label className="ms-checkbox-wrap">
                        <input
                          type="radio"
                          name="radioExample1"
                          disabled={!this.state.isEdit}
                          value=" "
                          checked={this.state.locationMediaType === " "}
                        />
                        <i className="ms-checkbox-check" />
                      </label>
                      <span> None </span>
                    </li>
                    <li className="ms-list-item pt-0">
                      <label className="ms-checkbox-wrap">
                        <input
                          type="radio"
                          name="radioExample1"
                          disabled={!this.state.isEdit}
                          value="image"
                          checked={this.state.locationMediaType === "image"}
                        />
                        <i className="ms-checkbox-check" />
                      </label>
                      <span> Image </span>
                    </li>
                    <li className="ms-list-item pt-0">
                      <label className="ms-checkbox-wrap">
                        <input
                          type="radio"
                          name="radioExample1"
                          disabled={!this.state.isEdit}
                          value="audio"
                          checked={this.state.locationMediaType === "audio"}
                        />
                        <i className="ms-checkbox-check" />
                      </label>
                      <span> Audio </span>
                    </li>
                    <li className="ms-list-item pt-0">
                      <label className="ms-checkbox-wrap">
                        <input
                          type="radio"
                          name="radioExample1"
                          disabled={!this.state.isEdit}
                          value="video"
                          checked={this.state.locationMediaType === "video"}
                        />
                        <i className="ms-checkbox-check" />
                      </label>
                      <span> Video </span>
                    </li>
                  </ul>
                </div>
              </div>

              {this.state.locationMediaType === "image" ? (
                <div className="form-group row">
                  <label
                    htmlFor="file"
                    className="col-sm-3 col-form-label col-form-label-sm "
                  >
                    Location Briefing Media
                  </label>
                  <div className="col-sm-9">
                    <div className="custom-file form-group">
                      <input
                        type="file"
                        className="form-control custom-file-input"
                        disabled={!this.state.isEdit}
                        onChange={this.uploadBreafingFile}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />

                      <label
                        className="custom-file-label"
                        htmlFor="validatedCustomFile"
                      ></label>
                    </div>
                    {this.state.breifingFile &&
                    this.state.breifingFile.length > 0 ? (
                      <div className="form-group edit-preview">
                        <i
                          className="fas fa-times-circle"
                          onClick={this.removeBreafingMedia}
                        ></i>
                        <img alt="img" src={this.state.breifingFile} />
                      </div>
                    ) : this.state.breifingMediaImg &&
                      this.props?.data?.breifing?.mediaType === "image" ? (
                      <div className="form-group edit-preview">
                        <i
                          className="fas fa-times-circle"
                          onClick={this.handleRemoveMediaProps}
                        ></i>
                        <img
                          alt="img"
                          src={IMAGE_URL + "" + this.state.breifingMediaImg}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {this.state.locationMediaType === "video" ? (
                <div className="form-group row">
                  <label
                    htmlFor="file"
                    className="col-sm-3 col-form-label col-form-label-sm "
                  >
                    Location Briefing Media
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      disabled={!this.state.isEdit}
                      className="form-control"
                      placeholder="Enter url"
                      value={this.state.locationVideoUrl}
                      onChange={this.handleVideoUrl}
                    />
                  </div>
                </div>
              ) : null}

              {this.state.locationMediaType === "audio" ? (
                <div className="form-group row">
                  <label
                    htmlFor="file"
                    className="col-sm-3 col-form-label col-form-label-sm "
                  >
                    Location Briefing Media
                  </label>
                  <div className="col-sm-9">
                    <div className="custom-file form-group">
                      <input
                        type="file"
                        disabled={!this.state.isEdit}
                        className="form-control custom-file-input"
                        onChange={this.uploadBreafingFile}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="validatedCustomFile"
                      ></label>
                    </div>
                    {this.state.breifingFile &&
                    this.state.breifingFile.length > 0 ? (
                      <div className="form-group preview">
                        <i
                          className="fas fa-times-circle"
                          onClick={this.removeBreafingMedia}
                        ></i>
                        <audio controls>
                          <source
                            src={this.state.breifingFile}
                            type="audio/mpeg"
                          />
                        </audio>
                      </div>
                    ) : this.state.breifingMediaImg &&
                      this.props?.data?.breifing?.mediaType === "audio" ? (
                      <div className="form-group preview">
                        <i
                          className="fas fa-times-circle"
                          onClick={this.handleRemoveMediaProps}
                        ></i>
                        <audio controls>
                          <source
                            src={IMAGE_URL + "" + this.state.breifingMediaImg}
                            type="audio/mpeg"
                          />
                        </audio>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {/* <div className="form-group row">
                <label
                  htmlFor="file"
                  className="col-sm-3 col-form-label col-form-label-sm "
                >
                  Breifing Media
                </label>
                <div className="col-sm-9">
                  <div className="custom-file form-group">
                    <input
                      type="file"
                      disabled={!this.state.isEdit}
                      className="form-control custom-file-input"
                      onChange={this.uploadBreafingFile}
                    />

                    <label
                      className="custom-file-label"
                      htmlFor="validatedCustomFile"
                    ></label>
                  </div>

                  {this.state.breifingFile &&
                  this.state.breifingFile?.length > 0 ? (
                    <>
                      {this.state.uploadBreafType === "image" ? (
                        <div className="form-group edit-preview">
                          <i
                            className="fas fa-times-circle"
                            onClick={this.removeBreafingMedia}
                          ></i>
                          <img alt="" src={this.state.breifingFile} />
                        </div>
                      ) : this.state.uploadBreafType === "audio" ? (
                        <div className="form-group preview">
                          <i
                            className="fas fa-times-circle"
                            onClick={this.removeBreafingMedia}
                          ></i>

                          <audio controls>
                            <source
                              src={this.state.breifingFile}
                              type="audio/mpeg"
                            />
                          </audio>
                        </div>
                      ) : this.state.uploadBreafType === "video" ? (
                        <div className="form-group preview">
                          <i
                            className="fas fa-times-circle"
                            onClick={this.removeBreafingMedia}
                          ></i>
                          <video width="320" height="240">
                            <source
                              src={this.state.breifingFile}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : null}
                    </>
                  ) : this.state.breifingMediaImg && this.state.breifingMediaImg.length &&
                    this.props.data?.breifing?.mediaType === "image" ? (
                    <div className="form-group edit-preview">
                      <i
                        className="fas fa-times-circle"
                        onClick={this.removeEditBreafingMedia}
                      ></i>
                      <img
                        alt=""
                        src={IMAGE_URL + "" + this.state.breifingMediaImg}
                      />
                    </div>
                  ) : this.state.breifingMediaImg && this.state.breifingMediaImg.length &&
                    this.props.data?.breifing?.mediaType === "audio" ? (
                    <div className="form-group preview">
                      <i
                        className="fas fa-times-circle"
                        onClick={this.removeEditBreafingMedia}
                      ></i>
                      <audio controls>
                        <source
                          src={IMAGE_URL + "" + this.state.breifingMediaImg}
                          type="audio/mpeg"
                        />
                      </audio>
                    </div>
                  ) : this.state.breifingMediaImg && this.state.breifingMediaImg.length &&
                    this.props.data?.breifing?.mediaType === "video" ? (
                    <div className="form-group preview">
                      <i
                        className="fas fa-times-circle"
                        onClick={this.removeEditBreafingMedia}
                      ></i>
                      <video width="320" height="240">
                        <source
                          src={IMAGE_URL + "" + this.state.breifingMediaImg}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  ) : null}
                </div>
              </div> */}

              <div className="form-group row">
                <label
                  htmlFor="checkincode"
                  className="col-sm-3 col-form-label col-form-label-sm "
                >
                  Check In Code
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    disabled={!this.state.isEdit}
                    className="form-control"
                    id="checkincode"
                    placeholder="checkincode name"
                    value={this.state.checkInCode}
                    onChange={this.handleCheckInCode}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="checkoutcode"
                  className="col-sm-3 col-form-label col-form-label-sm "
                >
                  Check Out Code
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    disabled={!this.state.isEdit}
                    className="form-control"
                    id="checkoutcode"
                    placeholder="checkoutcode name"
                    value={this.state.checkOutCode}
                    onChange={this.handleCheckOutCode}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="maxduration"
                  className="col-sm-3 col-form-label col-form-label-sm "
                >
                  Max Duration (Secs)
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    disabled={!this.state.isEdit}
                    className="form-control"
                    id="maxduration"
                    placeholder="maxduration"
                    value={this.state.maxDuration}
                    onChange={this.handleMaxDuration}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="minduration"
                  className="col-sm-3 col-form-label col-form-label-sm "
                >
                  Min Duration (Secs)
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    disabled={!this.state.isEdit}
                    className="form-control"
                    id="minduration"
                    placeholder="minduration"
                    value={this.state.minDuration}
                    onChange={this.handleMinDuration}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="codelocation"
                  className="col-sm-3 col-form-label col-form-label-sm"
                >
                  Address
                </label>
                <div className="col-sm-9">
                  <JoditEditor
                    value={this.state.checkInDesc}
                    config={this.checkconfig}
                    tabIndex={1}
                    onChange={(newContent) =>
                      this.handleCodeLocation(newContent)
                    }
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-6">
                  <label className="ms-checkbox-wrap ms-checkbox-primary">
                    <input
                      type="checkbox"
                      disabled={
                        !this.state.isEdit
                          ? true
                          : this.state.endLocation
                          ? true
                          : false
                      }
                      name="startlocation"
                      checked={this.state.startLocation}
                      onChange={this.handleStartLocation}
                    />
                    <i className="ms-checkbox-check" />
                  </label>
                  <span>Start Location</span>
                </div>

                <div className="col-md-6">
                  <label className="ms-checkbox-wrap ms-checkbox-primary">
                    <input
                      type="checkbox"
                      disabled={
                        !this.state.isEdit
                          ? true
                          : this.state.startLocation
                          ? true
                          : false
                      }
                      name="endlocation"
                      checked={this.state.endLocation}
                      onChange={this.handleEndLocation}
                    />
                    <i className="ms-checkbox-check" />
                  </label>
                  <span>End Location</span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="taskdesc">Task Description</label>

                <JoditEditor
                  value={this.state.taskDesc}
                  disabled={!this.state.isEdit}
                  tabIndex={1}
                  onChange={(newContent) => this.handleTaskDesc(newContent)}
                />
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label col-form-label-sm">
                  Task Media type
                </label>
                <div className="col-sm-8">
                  <ul
                    className="ms-list d-flex"
                    onChange={this.handleChangeTaskMedia}
                  >
                    <li className="ms-list-item pl-0 pt-0">
                      <label className="ms-checkbox-wrap">
                        <input
                          type="radio"
                          name="radioExample"
                          disabled={!this.state.isEdit}
                          value=""
                          checked={this.state.taskMediaType === ""}
                        />
                        <i className="ms-checkbox-check" />
                      </label>
                      <span> None </span>
                    </li>
                    <li className="ms-list-item pt-0">
                      <label className="ms-checkbox-wrap">
                        <input
                          type="radio"
                          name="radioExample"
                          disabled={!this.state.isEdit}
                          value="image"
                          checked={this.state.taskMediaType === "image"}
                        />
                        <i className="ms-checkbox-check" />
                      </label>
                      <span> Image </span>
                    </li>
                    <li className="ms-list-item pt-0">
                      <label className="ms-checkbox-wrap">
                        <input
                          type="radio"
                          name="radioExample"
                          disabled={!this.state.isEdit}
                          value="audio"
                          checked={this.state.taskMediaType === "audio"}
                        />
                        <i className="ms-checkbox-check" />
                      </label>
                      <span> Audio </span>
                    </li>
                    <li className="ms-list-item pt-0">
                      <label className="ms-checkbox-wrap">
                        <input
                          type="radio"
                          name="radioExample"
                          disabled={!this.state.isEdit}
                          value="video"
                          checked={this.state.taskMediaType === "video"}
                        />
                        <i className="ms-checkbox-check" />
                      </label>
                      <span> Video </span>
                    </li>
                  </ul>
                </div>
              </div>

              {this.state.taskMediaType === "image" ? (
                <div className="form-group row">
                  <label
                    htmlFor="file"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Location Media
                  </label>
                  <div className="custom-file form-group col-sm-8">
                    <input
                      type="file"
                      disabled={!this.state.isEdit}
                      className="form-control custom-file-input"
                      onChange={this.uploadSingleFile}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />

                    <label
                      className="custom-file-label"
                      htmlFor="validatedCustomFile"
                    ></label>
                  </div>
                  {this.state.file && this.state.file.length > 0 ? (
                    <div className="form-group edit-preview">
                      <i
                        className="fas fa-times-circle"
                        onClick={this.removeLocationMedia}
                      ></i>
                      <img alt="img" src={this.state.file} />
                    </div>
                  ) : this.state.taskMediaType &&
                    this.props.data?.mediaType === "image" ? (
                    <div className="form-group edit-preview">
                      <i
                        className="fas fa-times-circle"
                        onClick={this.removeEditLocationMedia}
                      ></i>
                      <img
                        alt=""
                        src={IMAGE_URL + "" + this.state.locationMediaImg}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}

              {this.state.taskMediaType === "audio" ? (
                <div className="form-group row">
                  <label
                    htmlFor="file"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Location Media
                  </label>
                  <div className="custom-file form-group col-sm-8">
                    <input
                      type="file"
                      disabled={!this.state.isEdit}
                      className="form-control custom-file-input"
                      onChange={this.uploadSingleFile}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />

                    <label
                      className="custom-file-label"
                      htmlFor="validatedCustomFile"
                    ></label>
                  </div>
                  {this.state.file && this.state.file.length > 0 ? (
                    <div className="form-group preview">
                      <i
                        className="fas fa-times-circle"
                        onClick={this.removeLocationMedia}
                      ></i>
                      <audio controls>
                        <source src={this.state.file} type="audio/mpeg" />
                      </audio>
                    </div>
                  ) : this.state.taskMediaType &&
                    this.props.data?.mediaType === "audio" ? (
                    <div className="form-group preview">
                      <i
                        className="fas fa-times-circle"
                        onClick={this.removeEditLocationMedia}
                      ></i>

                      <audio controls>
                        <source
                          src={IMAGE_URL + "" + this.state.locationMediaImg}
                          type="audio/mpeg"
                        />
                      </audio>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {this.state.taskMediaType === "video" ? (
                <div className="form-group row">
                  <label
                    htmlFor="file"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Location Media
                  </label>
                  <div className="custom-file form-group col-sm-8">
                    <input
                      type="text"
                      disabled={!this.state.isEdit}
                      className="form-control"
                      placeholder="Enter url"
                      value={this.state.taskVideoUrl}
                      onChange={this.handleTaskVideoUrl}
                    />
                  </div>
                </div>
              ) : null}

              {/* <div className="form-group">
                <label htmlFor="file">Location Media</label>
                <div className="custom-file form-group">
                  <input
                    type="file"
                    disabled={!this.state.isEdit}
                    className="form-control custom-file-input"
                    onChange={this.uploadSingleFile}
                  />

                  <label
                    className="custom-file-label"
                    htmlFor="validatedCustomFile"
                  ></label>
                </div>

                {this.state.file && this.state.file.length > 0 ? (
                  <>
                    {this.state.uploadType === "image" ? (
                      <div className="form-group edit-preview">
                        <i
                          className="fas fa-times-circle"
                          onClick={this.removeLocationMedia}
                        ></i>
                        <img alt="" src={this.state.file} />
                      </div>
                    ) : this.state.uploadType === "audio" ? (
                      <div className="form-group preview">
                        <i
                          className="fas fa-times-circle"
                          onClick={this.removeLocationMedia}
                        ></i>

                        <audio controls>
                          <source src={this.state.file} type="audio/mpeg" />
                        </audio>
                      </div>
                    ) : this.state.uploadType === "video" ? (
                      <div className="form-group preview">
                        <i
                          className="fas fa-times-circle"
                          onClick={this.removeLocationMedia}
                        ></i>
                        <video width="320" height="240">
                          <source src={this.state.file} type="video/mp4" />
                        </video>
                      </div>
                    ) : null}
                  </>
                ) : this.state.locationMediaImg &&
                  this.props.data?.mediaType === "image" ? (
                  <div className="form-group edit-preview">
                    <i
                      className="fas fa-times-circle"
                      onClick={this.removeEditLocationMedia}
                    ></i>
                    <img
                      alt=""
                      src={IMAGE_URL + "" + this.state.locationMediaImg}
                    />
                  </div>
                ) : this.state.locationMediaImg &&
                  this.props.data?.mediaType === "audio" ? (
                  <div className="form-group preview">
                    <i
                      className="fas fa-times-circle"
                      onClick={this.removeEditLocationMedia}
                    ></i>
                    <audio controls>
                      <source
                        src={IMAGE_URL + "" + this.state.locationMediaImg}
                        type="audio/mpeg"
                      />
                    </audio>
                  </div>
                ) : this.state.locationMediaImg &&
                  this.props.data?.mediaType === "video" ? (
                  <div className="form-group preview">
                    <i
                      className="fas fa-times-circle"
                      onClick={this.removeEditLocationMedia}
                    ></i>
                    <video width="320" height="240">
                      <source
                        src={IMAGE_URL + "" + this.state.locationMediaImg}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                ) : null}
              </div> */}

              <div className="form-group text-left">
                <Button
                  onClick={this.handleModal}
                  // type="submit"
                  className=" btn btn-pill btn-primary mr-1 redbutton"
                >
                  Delete Location
                  {this.state.deleteLoading && (
                    <Spinner
                      animation="border"
                      variant="light"
                      size="sm"
                      className="ml-2"
                    />
                  )}
                </Button>
                {!this.state.isEdit ? (
                  <button
                    type="button"
                    onClick={this.handleEditLocation}
                    className="btn btn-pill btn-primary  redbutton"
                  >
                    Edit Location
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={this.handlUpdateLocation}
                    className="btn btn-pill btn-primary  redbutton"
                  >
                    Save Location
                    {this.state.editLoading && (
                      <Spinner
                        animation="border"
                        variant="light"
                        size="sm"
                        className="ml-2"
                      />
                    )}
                  </button>
                )}
              </div>
              <Modal
                show={this.state.show}
                onHide={this.handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body>
                  <h4>Are you sure you want to delete?</h4>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-light btn-pill"
                    onClick={this.handleClose}
                  >
                    No
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary btn-pill redbutton shadow-none"
                    onClick={this.handleLocationDelete}
                  >
                    Yes
                    {this.state.deleteLoading && (
                      <Spinner
                        animation="border"
                        variant="light"
                        size="sm"
                        className="ml-2"
                      />
                    )}
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.LocationList.locationList,
    fetching: state.LocationList.fetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetLocationList: (id) => dispatch(getLocationList(id)),
    GetRouteList: (id) => dispatch(getRouteList(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLocation);
