import {
  GET_PROGRESS_REPORT_SUCCESS,
  GET_PROGRESS_REPORT_REQUEST,
  GET_PROGRESS_REPORT_ERROR,
} from "./../../utils/constant";

const initialState = {
  fetching: false,
  ProgressData: [],
  error: {},
};

export const ProgressReport = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROGRESS_REPORT_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_PROGRESS_REPORT_SUCCESS:
      return {
        ...state,
        ProgressData: action.payload,
        fetching: false,
      };
    case GET_PROGRESS_REPORT_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
