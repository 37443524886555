import { showToast } from "../common/ToastMsg";
import { apiInstance } from "./../../httpClient/index";

export const getProgressReport = async (id) => {
  try {
    const res = await apiInstance.get(`progress/${id}`);
    if (res.status === 200) {
      return res.data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error.response);
    showToast("error", error.response?.data?.message);
    return null;
  }
};
