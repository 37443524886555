import React, { Component } from "react";

class Breadcrumb extends Component {
  render() {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="heading-title">Create A New Race</h4>
          <button
            type="button"
            className="btn btn-pill btn-primary m-0 redbutton"
          >
            + Add A Race
          </button>
        </div>
      </>
    );
  }
}

export default Breadcrumb;
