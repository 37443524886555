import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setLogin } from "./../../actions/login";

class Content extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      error: "",
    };
  }

  handleSubmit = async (values) => {
    const loginData = {
      email: values.email,
      password: values.password,
      role: "admin",
    };
    this.setState({
      loading: true,
    });
    const res = await setLogin(loginData);
    if (res) {
      this.setState({
        loading: false,
      });
      this.props.history.replace("/race-list");
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    return (
      <div className="ms-content-wrapper ms-auth">
        <div className="ms-auth-container">
          <div className="ms-auth-col">
            <div className="ms-auth-bg" />
          </div>
          <div className="ms-auth-col">
            <div className="ms-auth-form login-form">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Email is invalid")
                    .required("Email is required"),
                  password: Yup.string()
                    .min(6, "Password must be at least 6 characters")
                    .required("Password is required"),
                })}
                onSubmit={this.handleSubmit}
                render={({ errors, status, touched }) => (
                  <Form>
                    <h3 className="mb-4">Login to Admin</h3>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Field
                        name="email"
                        type="text"
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <Field
                        name="password"
                        type="password"
                        className={
                          "form-control" +
                          (errors.password && touched.password
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-pill redbutton btn-primary mr-2"
                      >
                        Login
                        {this.state.loading && (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                            className="ml-2"
                          />
                        )}
                      </button>
                    </div>
                    {this.state.error && (
                      <p style={{ color: "#F00", display: "block" }}>
                        {this.state.error}
                      </p>
                    )}
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Content);
