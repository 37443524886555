import { apiInstance } from "../../httpClient";
import { showToast } from "../common/ToastMsg";

export const getGroupList = async () => {
  try {
    const res = await apiInstance.get(`group/get-all`);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const updateGroupList = async (id, groupBody) => {
  try {
    const res = await apiInstance.put(`group/update/${id}`, groupBody);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const CreateGroupList = async (groupBody) => {
  try {
    const res = await apiInstance.post(`group/create`, groupBody);
    if (res.status === 200) {
      return res.data.data;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};

export const deleteGroup = async (id) => {
  try {
    const res = await apiInstance.delete(`group/remove/${id}`);
    if (res.status === 200) {
      return res;
    }
    return null;
  } catch (error) {
    showToast("error", error.response?.data?.message);
    return null;
  }
};
