import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistCombineReducers } from "redux-persist";
import { RaceList } from "../reducers/RaceList";
import { LocationList } from "../reducers/LocationList";
import { RouteList } from "../reducers/RouteList";
import { GroupList } from "../reducers/GroupList";
import { RaceByGroup } from "../reducers/RaceByGroup";
import { SkinList } from "../reducers/SkinList";
import { RaceBySkin } from "../reducers/RaceBySkin";
import { TeamList } from "../reducers/TeamList";
import { QuestionList } from "../reducers/QuestionList";
import { ProgressReport } from "../reducers/ProgressReport";
import { ReviewList } from "../reducers/ReviewList";
import { TeamStatus } from "../reducers/TeamStatus";

const config = {
  key: "primary",
  storage: AsyncStorage
};

const state = {
  RaceList,
  LocationList,
  RouteList,
  GroupList,
  RaceByGroup,
  SkinList,
  RaceBySkin,
  TeamList,
  QuestionList,
  ProgressReport,
  ReviewList,
  TeamStatus,
};

export default persistCombineReducers(config, state);
